// frontend/src/data/CompetitionEnvironmentData.js

const getCompetitionEnvironmentData = (environments, commits) => {
    return {
        initialData: (competitionId) => ({
            environment_id: '',
            publish_commit: '',
            competition_id: competitionId,
            environments: [{ value: '', label: 'Select Environment' }],
            commits: [{ value: '', label: 'Select Commit' }],
        }),
        setOnceFields: [
            { name: 'environment_id', type: 'select', label: 'Environment', options: environments || [{ value: '', label: 'Select Environment' }]},
            { name: 'publish_commit', type: 'select', label: 'Publish Commit', options: commits || [{ value: '', label: 'Select Commit' }]},
        ],
        changeableFields: [],
        fieldsChangedAfterCreation: [],
        displayOnlyFields: [
            { name: 'created_at_ts', label: 'Created At', type: 'date' },
            { name: 'is_passed_test', label: 'Passed Test', type: 'checkbox', disabled: true }
        ]
    };
};

export default getCompetitionEnvironmentData;
