import React from 'react';
import PropTypes from 'prop-types';
import { deleteCompetition } from 'services/apiService';
import { useNavigate } from 'react-router-dom';

const DeleteComponent = ({ objectId, apiDelete, onDeleted, navpath}) => {
    const navigate = useNavigate();
    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this object?')) {
            apiDelete(objectId)
            .then(() => {
                alert('Object deleted successfully.');
                if (onDeleted) {
                    onDeleted();
                }
                if (navpath)
                {
                    navigate(`${navpath}`);
                }
                else
                {window.location.reload();}
            })
            .catch((error) => {
                console.error('Error:', error);
                alert('Error deleting object');
            });
        }
    };

    return <button onClick={handleDelete} className="btn btn-danger">Delete</button>;
};

DeleteComponent.propTypes = {
    // objectId: PropTypes.number.isRequired,
    // onDeleted: PropTypes.func.isRequired,
};

export default DeleteComponent;
