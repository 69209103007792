// frontend/src/components/DeleteRepository.js

import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const DeleteRepository = ({ apiEndpoint, objectId, onDeleted }) => {
    const navigate = useNavigate();

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this repository?')) {
            fetch(`${apiEndpoint}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Network response was not ok.');
            })
            .then(() => {
                alert('Repository deleted successfully.');
                onDeleted()
            })
            .catch(error => {
                console.error('Error:', error);
                alert('Error deleting repository');
            });
        }
    };

    return (
        <button onClick={handleDelete} className="btn btn-danger">Delete</button>
    );
};

DeleteRepository.propTypes = {
    apiEndpoint: PropTypes.string.isRequired,
    objectId: PropTypes.string.isRequired
};

export default DeleteRepository;
