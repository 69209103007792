import { useState, useEffect, useCallback } from "react";
import {
  fetchAttachmentCompetition,
  fetchAttachment,
  saveConfiguration,
  deleteConfiguration,
  fetchEngines,
} from "services/apiService";
import getCompetitionConfigurationData from "data/CompetitionConfigurationData"; // Ensure this import is correct

export const useConfigurationData = (competitionId, configurationId) => {
  const [configurationData, setConfigurationData] = useState(
    getCompetitionConfigurationData().initialData(competitionId)
  );
  const [configurationLoading, setLoading] = useState(false);
  const [configurationError, setError] = useState(null);

  useEffect(() => {
    if (!configurationId) {
      setLoading(true);
      fetchEngines()
        .then((engines) => {
          const formattedEngines = engines.map((engine) => ({
            value: engine.id,
            label: engine.name,
          }));
          setConfigurationData((prevState) => {
            return {
              ...prevState,
              engines: formattedEngines,
              engine_id: formattedEngines[0].value,
            };
          });
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, []);

  // Effect to fetch existing configuration data for the competition
  useEffect(() => {
    if (configurationId) {
      setLoading(true);
      fetchAttachmentCompetition(competitionId, "configuration")
        .then((data) => {
          setConfigurationData((prevData) => ({
            ...prevData,
            ...data,
          }));
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, [configurationId]);

  const handleSaveConfiguration = useCallback(
    (data) => {
      setLoading(true);
      return saveConfiguration(configurationId, data)
        .then((responseData) => {
          setConfigurationData((prevData) => ({
            ...prevData,
            ...responseData,
          }));
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    },
    [competitionId]
  );

  const handleDeleteConfiguration = useCallback(() => {
    setLoading(true);
    return deleteConfiguration(competitionId)
      .then(() => {
        setConfigurationData(
          getCompetitionConfigurationData().initialData(competitionId)
        );
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [competitionId]);

  const updateAttachConfigurationData = useCallback((updatedData) => {
    setConfigurationData((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  }, []);

  return {
    configurationData,
    configurationLoading,
    configurationError,
    handleSaveConfiguration,
    handleDeleteConfiguration,
    updateAttachConfigurationData,
  };
};

export default useConfigurationData;
