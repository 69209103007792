// ObjectData.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GenericFields from 'components/FormElements/GenericFields';
import DisplayOnlyFields from 'components/FormElements/DisplayOnlyFields';


const ObjectDataRepository = ({IsPost, objectData, updateObjectData, setOnceFields, changeableFields, fieldsChangedAfterCreation, displayOnlyFields, onPrimaryOptionChange }) => {
    const handleChange = (e) => {
      const { name, value } = e.target;
      updateObjectData({ [name]: value });
    };
    return (
        <div>
            {IsPost && (
                                <>
                <GenericFields
                    fields={setOnceFields}
                    objectData={objectData}
                    onObjectDataChange={handleChange}
                    isEditable={true}
                />
                            <GenericFields
                fields={changeableFields}
                objectData={objectData}
                onObjectDataChange={handleChange}
                isEditable={true}
            />
                </>
            )}

            {!IsPost && (
                <>
                <GenericFields
                fields={setOnceFields}
                objectData={objectData}
                onObjectDataChange={handleChange}
                isEditable={false}
                            />
                <GenericFields
                    fields={fieldsChangedAfterCreation}
                    objectData={objectData}
                    onObjectDataChange={handleChange}
                    isEditable={true}
                />
                                            <GenericFields
                fields={changeableFields}
                objectData={objectData}
                onObjectDataChange={handleChange}
                isEditable={true}
            />
                </>
            )}
            <DisplayOnlyFields
                displayFields={displayOnlyFields}
                objectData={objectData}
            />
        </div>
    );
};

ObjectDataRepository.propTypes = {
    // objectData: PropTypes.number,
    // updateObjectData: PropTypes.object.isRequired,
    setOnceFields: PropTypes.array.isRequired,
    changeableFields: PropTypes.array.isRequired,
    fieldsChangedAfterCreation: PropTypes.array.isRequired,
    displayOnlyFields: PropTypes.array.isRequired
};

export default ObjectDataRepository;