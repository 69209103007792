// SignIn.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextInput, PasswordInput, Button, Group, Stack, Text, Anchor, Title, Container, Paper, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useAuth } from 'context/AuthContext';
import { showNotification } from '@mantine/notifications';
import authService from 'services/authService';

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setIsAuthenticated, setCurrentUser } = useAuth();

  const form = useForm({
    initialValues: {
      username: '',
      password: '',
    },
    validate: {
      username: (value) => (value.length < 3 ? 'Username must be at least 3 characters' : null),
      password: (value) => (value.length < 6 ? 'Password must be at least 6 characters' : null),
    },
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await authService.login(values.username, values.password);
      setCurrentUser({ username: response.username });
      setIsAuthenticated(true);
      showNotification({
        title: 'Success',
        message: 'Login successful',
        color: 'green',
      });
      navigate('/listcompetition');
    } catch (error) {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container size={420} my={40}>
      <Title align="center" sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}>
        Welcome back!
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Don't have an account yet?{' '}
        <Anchor size="sm" onClick={() => navigate('/login-mail/register')}>
          Create account
        </Anchor>
      </Text>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md" pos="relative">
        <LoadingOverlay visible={loading} overlayblur={2} />
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <TextInput
              label="Username"
              placeholder="Your username"
              required
              {...form.getInputProps('username')}
            />
            <PasswordInput
              label="Password"
              placeholder="Your password"
              required
              {...form.getInputProps('password')}
            />
            <Anchor component="button" type="button" color="dimmed" onClick={() => navigate('/login-mail/forgot-password')} size="xs">
              Forgot password?
            </Anchor>
            <Button fullWidth mt="xl" type="submit">
              Sign in
            </Button>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
};

export default SignIn;