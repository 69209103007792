// frontend/src/components/SaveRepository.js

import React from 'react';
import PropTypes from 'prop-types';

const SaveRepository = ({ apiEndpoint, objectId, fileContent, objectData, onSaved }) => {
    const handleSave = () => {
        const method = objectId ? 'PUT' : 'POST';
        const data = {
            ...objectData,
            file_content: fileContent,
        };

        fetch(apiEndpoint, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include',
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            return response.json();
        })
        .then(data => {
            alert(data.message);
            if (onSaved) {
                onSaved(data);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            alert('Error saving data');
        });
    };

    return (
        <button onClick={handleSave} className="btn btn-primary">Save</button>
    );
};

SaveRepository.propTypes = {
    apiEndpoint: PropTypes.string.isRequired,
    objectId: PropTypes.string,
    fileContent: PropTypes.string.isRequired,
    objectData: PropTypes.object.isRequired,
    onSaved: PropTypes.func,
};

export default SaveRepository;
