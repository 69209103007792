import React, { useState, useEffect } from 'react';
import { Card, Text, Group, Grid, Loader, Alert, Stack, ThemeIcon, Button } from '@mantine/core';
import { IconAlertCircle, IconClock, IconTrophy, IconRun, IconChartBar, IconCpu, IconDeviceDesktop, IconUsers, IconAlertTriangle, IconRefresh, IconCrown } from '@tabler/icons-react';

const AgentResultOverview = ({ competitionId, attacheAgentId }) => {
  const [overview, setOverview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchOverview = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_PREFIX_BACKEND}/api/agent_attached_result/${competitionId}/${attacheAgentId}/overview`);
      if (!response.ok) {
        throw new Error('Failed to fetch overview data');
      }
      const data = await response.json();
      setOverview(data);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOverview();
  }, [competitionId, attacheAgentId]);

  const handleRefresh = () => {
    fetchOverview();
  };

  if (loading) return <Loader />;
  if (error) return <Alert icon={<IconAlertCircle size="1rem" />} title="Error" color="red">{error}</Alert>;
  if (!overview) return null;

  const InfoCard = ({ icon, title, value }) => (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Group position="apart" style={{ marginBottom: 5, marginTop: 5 }}>
        <Text size="sm" color="dimmed">{title}</Text>
        <ThemeIcon color="blue" variant="light">
          {icon}
        </ThemeIcon>
      </Group>
      <Text size="xl" weight={700}>{value}</Text>
    </Card>
  );

  return (
    <Stack>
      <Group position="apart" align="center">
        <Text size="xl" weight={700}>Agent Performance Overview</Text>
        <Button 
          leftIcon={<IconRefresh size="1rem" />} 
          onClick={handleRefresh}
          loading={loading}
        >
          Refresh
        </Button>
      </Group>
      <Grid gutter="md">
        <Grid.Col span={4}>
          <InfoCard
            icon={<IconCrown size="1.5rem" />}
            title="Rank"
            value={overview.rank || 'N/A'}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <InfoCard
            icon={<IconTrophy size="1.5rem" />}
            title="Mean Reward"
            value={overview.mean_reward?.toFixed(2) || 'N/A'}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <InfoCard
            icon={<IconRun size="1.5rem" />}
            title="Total Runs"
            value={overview.number_of_runs}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <InfoCard
            icon={<IconChartBar size="1.5rem" />}
            title="Runs in Last 24h"
            value={overview.runs_last_24h}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <InfoCard
            icon={<IconCpu size="1.5rem" />}
            title="Avg CPU Usage"
            value={`${overview.avg_cpu_usage?.toFixed(2) || 'N/A'}%`}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <InfoCard
            icon={<IconDeviceDesktop size="1.5rem" />}
            title="Max RAM Usage"
            value={`${overview.max_ram_usage?.toFixed(2) || 'N/A'}%`}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <InfoCard
            icon={<IconUsers size="1.5rem" />}
            title="Playing soon (in queue):"
            value={overview.agents_in_queue}
          />
        </Grid.Col>
      </Grid>
      <Group grow>
        <Card shadow="sm" p="lg" radius="md" withBorder>
          <Group position="apart">
            <Text size="sm" color="dimmed">Created At</Text>
            <ThemeIcon color="blue" variant="light">
              <IconClock size="1.5rem" />
            </ThemeIcon>
          </Group>
          <Text size="md">{new Date(overview.created_at).toLocaleString()}</Text>
        </Card>
        <Card shadow="sm" p="lg" radius="md" withBorder>
          <Group position="apart">
            <Text size="sm" color="dimmed">Last Run</Text>
            <ThemeIcon color="blue" variant="light">
              <IconClock size="1.5rem" />
            </ThemeIcon>
          </Group>
          <Text size="md">{overview.last_end_run_ts ? new Date(overview.last_end_run_ts).toLocaleString() : 'N/A'}</Text>
        </Card>
      </Group>
      {overview.latest_error && (
        <Alert icon={<IconAlertTriangle size="1rem" />} title="Latest Error" color="red" mt="md">
          {overview.latest_error}
        </Alert>
      )}
    </Stack>
  );
};

export default AgentResultOverview;