import { useState, useEffect } from 'react';

const useFetchAndManageRepositoryContent = (apiEndpoint, objectId) => {
    const [repositoryContent, setRepositoryContent] = useState({
        fileContent: '',
        selectedCommit: '',
        commits: []
    });

    const fetchFileContent = (commitHash) => {
        const commitEndpoint = commitHash ? `${apiEndpoint}?commit=${commitHash}` : apiEndpoint;
        fetch(commitEndpoint, { credentials: 'include' })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setRepositoryContent(prev => ({
                        ...prev,
                        fileContent: data.file_content || '',
                        selectedCommit: commitHash || prev.selectedCommit,
                        commits: data.commits || prev.commits
                    }));
                }
            })
            .catch(error => console.error('Error fetching file content: ', error));
    };

    useEffect(() => {
        if (objectId) {
            fetchFileContent(repositoryContent.selectedCommit);
        }
    }, [apiEndpoint, objectId, repositoryContent.selectedCommit]);

    const updateFileContent = (newFileContent) => {
        setRepositoryContent(prev => ({ ...prev, fileContent: newFileContent }));
    };

    const updateSelectedCommit = (newCommit) => {
        fetchFileContent(newCommit); // Fetch file content for the new commit
    };

    return [repositoryContent, updateFileContent, updateSelectedCommit];
};

export default useFetchAndManageRepositoryContent;
