import React from 'react';
import { Stack, Title, Container, Group, Button, Box } from '@mantine/core';
import LiveStream from 'components/Competition/LiveStream';
import Timer from 'components/Competition/Timer';
import CompetitionConstraints from 'components/Competition/CompetitionConstraints';
import MarkdownEditor from 'components/Competition/MarkdownEditor';

const Overview = ({ 
  competitionId, 
  startTime, 
  endTime, 
  renderDelay, 
  agentMemoryLimit, 
  agentCpuLimit, 
  agentMaxTimePerStepSecond,
  onJoinCompetition 
}) => {
  return (
    <Container size="xl" px="xs">

      
      <Stack spacing="xl">
        <Group grow align="flex-start">
          <Timer startTime={startTime} endTime={endTime} />
          <CompetitionConstraints
            memoryLimit={agentMemoryLimit || 'N/A'}
            cpuLimit={agentCpuLimit || 'N/A'}
            maxTimePerStep={agentMaxTimePerStepSecond || 'N/A'}
          />
        </Group>
        
        {renderDelay !== undefined && (
          <Box>

            <LiveStream renderDelay={renderDelay * 1000} />
          </Box>
        )}
        
        <Box>
          <MarkdownEditor competitionId={competitionId} isViewOnly={true} />
        </Box>
      </Stack>
    </Container>
  );
};

export default Overview;