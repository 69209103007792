// frontend/src/data/CompetitionConfigurationData.js

export const getCompetitionConfigurationData = (engines) => {
  return {
    initialData: (competitionId) => {
      return {
        number_of_agents: "1",
        agent_max_time_per_step_second: "1",
        render_delay_second: "0.5",
        daily_run_limit: "10",
        notebook_blueprint_colab: "",
        engine_id: "",
        engines: [{ value: "", label: "Select Environment" }],
        competition_id: competitionId,
      };
    },
    setOnceFields: [
      {
        name: "number_of_agents",
        type: "number",
        label: "Number of Agents",
        placeholder: "Enter number of agents",
      },
      {
        name: "agent_max_time_per_step_second",
        type: "number",
        label: "Agent Max Time per Step (s)",
        placeholder: "Enter max time per step",
      },
    ],
    changeableFields: [
      {
        name: "engine_id",
        type: "select",
        label: "Engine",
        options: engines || [{ value: "", label: "Select Environment" }],
      },
      {
        name: "daily_run_limit",
        type: "number",
        label: "daily_run_limit",
        placeholder: "Enter daily_run_limit",
      },
      {
        name: "render_delay_second",
        type: "number",
        label: "render_delay_second (s)",
        placeholder: "Enter render_delay_second",
      },
      {
        name: "notebook_blueprint_colab",
        type: "text",
        label: "notebook_blueprint_colab adresse",
        placeholder: "Enter notebook_blueprint_colab",
      },
    ],
    fieldsChangedAfterCreation: [],
    displayOnlyFields: [
      { name: "created_at_ts", label: "Created At", type: "date" },
      { name: "competition_id", label: "Competition ID", type: "text" },
    ],
  };
};

export default getCompetitionConfigurationData;
