import React from "react";
import PropTypes from "prop-types";
import DisplayField from "./DisplayField";

const DisplayOnlyFields = ({ displayFields, objectData }) => {
  return (
    <div>
      {displayFields.map((field) => (
        <div key={field.name} className="mb-3">
          {field.label}:
          <span className="ms-2">
            {DisplayField(objectData[field.name], field.type)}
          </span>
        </div>
      ))}
    </div>
  );
};

DisplayOnlyFields.propTypes = {
  displayFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  objectData: PropTypes.object.isRequired,
};

export default DisplayOnlyFields;
