import React from 'react';
import { Paper, Title, Text, Stack, Select, Code, Tooltip, ActionIcon, Group, List, ThemeIcon, Card } from '@mantine/core';
import { IconCopy, IconFileText } from '@tabler/icons-react';
import { useClipboard } from '@mantine/hooks';
import { Editor } from '@monaco-editor/react';
import axios from 'axios';

const useAgentConfiguration = (competitionId, attacheAgentId) => {
  const [configuration, setConfiguration] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchConfiguration = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`/api/direct_attache_agents/competition/${competitionId}/${attacheAgentId}`);
        setConfiguration(response.data);
      } catch (error) {
        console.error("Error fetching agent configuration:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchConfiguration();
  }, [competitionId, attacheAgentId]);

  return { configuration, isLoading };
};

const Configuration = ({ competitionId, attacheAgentId }) => {
  const clipboard = useClipboard({ timeout: 500 });
  const { configuration, isLoading } = useAgentConfiguration(competitionId, attacheAgentId);

  const imageOptions = [
    { value: 'python:3.10-stable-baseline3:2.3.0', label: 'python:3.10 - stable-baseline3:2.3.0' },
  ];

  const editorOptions = {
    minimap: { enabled: false },
    lineNumbers: 'on',
    readOnly: true,
    automaticLayout: true,
  };

  if (isLoading) {
    return <Text>Loading configuration...</Text>;
  }

  if (!configuration) {
    return <Text>No configuration found.</Text>;
  }
  const requirementsTxt = `python = "3.10"

gymnasium = {extras = ["box2d"], version = "0.29.1"}
stable-baselines3 = "2.3.0"
autorom = {extras = ["accept-rom-license"], version = "0.6.1"}
pettingzoo = {extras = ["classic", atari], version = "1.24.3"}
sb3-contrib = "2.3.0"`;
  return (
    <Stack spacing="md">
      <Paper shadow="xs" p="md">
        
        {/* Agent Runtime Environment */}
        <Title order={4} mb="md">Agent Runtime Environment</Title>
        <Select
          label="Docker Image for Agent Runtime"
          placeholder="Select a Docker image"
          data={imageOptions}
          value={'python:3.10-stable-baseline3:2.3.0'}
          readOnly
          mb="md"
        />
        <Title order={5} mb="sm">Requirements</Title>
        <div style={{ position: 'relative' }}>
          <Code block mb="md">{requirementsTxt}</Code>
          <Tooltip
            label={clipboard.copied ? "Copied!" : "Copy requirements.txt"}
            position="left"
          >
            <ActionIcon
              style={{
                position: 'absolute',
                bottom: '8px',
                right: '8px',
                background: 'white',
                boxShadow: '0 0 5px rgba(0,0,0,0.1)',
              }}
              onClick={() => clipboard.copy(requirementsTxt)}
            >
              <IconCopy size="1rem" />
            </ActionIcon>
          </Tooltip>
        </div>
      </Paper>

      {/* Agent Files */}
      <Paper shadow="xs" p="md">
        <Title order={4} mb="md">Agent Files</Title>
        <Card shadow="sm" p="lg" mb="md">
          <List spacing="xs" size="sm" center icon={
            <ThemeIcon color="teal" size={24} radius="xl">
              <IconFileText size={16} />
            </ThemeIcon>
          }>
            {configuration.files.map((file, index) => (
              <List.Item
                key={index}
                icon={
                  <ThemeIcon color={file === 'agent.py' ? 'blue' : 'teal'} size={24} radius="xl">
                    <IconFileText size={16} />
                  </ThemeIcon>
                }
              >
                <Text>{file}{file === 'agent.py' && ' (Main File)'}</Text>
              </List.Item>
            ))}
          </List>
        </Card>

        {/* Agent Code Display */}
        {configuration.agentCode && (
          <Card shadow="sm" p="lg">
            <Group position="apart" mb="xs">
              <Text weight={500}>Agent Code (agent.py)</Text>
            </Group>
            <Editor
              height="400px"
              language="python"
              theme="vs-dark"
              value={configuration.agentCode}
              options={editorOptions}
            />
          </Card>
        )}
      </Paper>
    </Stack>
  );
};

export default Configuration;