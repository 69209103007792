// displayField.js
const DisplayField = (fieldValue, fieldType) => {
    console.log("DisplayField:", fieldValue, fieldType);
    if ((fieldType === 'date' || fieldType === 'datetime-local') && fieldValue) {
        const date = new Date(fieldValue);
        if (!isNaN(date.getTime())) {
            // Format: YYYY-MM-DD HH:mm:ss
            return date.toISOString().slice(0, 19).replace('T', ' ');
        }
    }
    return fieldValue;
};

export default DisplayField;