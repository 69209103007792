// src/hooks/useCompetitionData.js
import { useState, useEffect, useCallback } from "react";
import {
  fetchCompetition,
  saveCompetition,
  deleteCompetition,
  fetchAttachments,
} from "services/apiService";
import getCompetitionData from "data/CompetitionData"; // Assuming similar function for competition data

export const useCompetitionData = (competitionId) => {
  const [competitionData, setCompetitionData] = useState(
    getCompetitionData().initialData
  );
  const [competitionLoading, setLoading] = useState(false);
  const [competitionError, setError] = useState(null);
  // State for attachment IDs
  const [attachmentIds, setAttachments] = useState({
    configurationId: null,
    environmentId: null,
    agentIds: [],
  });

  useEffect(() => {
    setLoading(true);
    const fetchAllData = async () => {
      try {
        const compData = competitionId
          ? await fetchCompetition(competitionId)
          : getCompetitionData().initialData;
        setCompetitionData(compData);
        if (competitionId) {
          const attachmentData = await fetchAttachments(competitionId);
          setAttachments({
            configurationId: attachmentData.configurationId,
            environmentId: attachmentData.environmentId,
            agentIds: attachmentData.agentIds,
          });
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [competitionId]);

  const handleSaveCompetition = useCallback(
    (data) => {
      setLoading(true);
      return saveCompetition(competitionId, data)
        .then((responseData) => {
          setCompetitionData((prevData) => ({
            ...prevData,
            ...responseData,
          }));
        })
        .catch(setError)
        .finally(() => setLoading(false));
    },
    [competitionId]
  );

  const handleDeleteCompetition = useCallback(() => {
    setLoading(true);
    return deleteCompetition(competitionId)
      .then(() => {
        setCompetitionData(getCompetitionData().initialData);
        setAttachments({
          configurationId: null,
          environmentId: null,
          agentIds: [],
        });
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [competitionId]);

  const updateCompetitionData = useCallback((updatedData) => {
    setCompetitionData((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  }, []);

  // Return the additional attachment IDs along with the existing states and handlers
  return {
    competitionData,
    competitionLoading,
    competitionError,
    handleSaveCompetition,
    handleDeleteCompetition,
    updateCompetitionData,
    ...attachmentIds, // Spread attachment IDs into the return value
  };
};

export default useCompetitionData;
