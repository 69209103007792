import { useState, useEffect } from "react";
import axios from "axios";

axios.defaults.baseURL = `${process.env.REACT_APP_PREFIX_BACKEND}`;

export const useAgentAttachment = (competitionId, attacheAgentId) => {
  const [attachment, setAttachment] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [agentCode, setagentCode] = useState("");
  const [notebookBlueprintColab, setNotebookBlueprintColab] = useState("");
  const [showMaxAgentsModal, setShowMaxAgentsModal] = useState(false);

  const [dailyAgentRunLimit, setDailyAgentRunLimit] = useState(0);
  const [totalDailyRunAllocation, setTotalDailyRunAllocation] = useState(0);
  const [dailyRunAllocation, setDailyRunAllocation] = useState(0);
  const [newDailyRunAllocation, setNewDailyRunAllocation] = useState(0);

  // Initializing state for lifecycle states and loading indicators
  const [uploadState, setUploadState] = useState({
    isBlock: true,
    isOk: null,
    isLoading: false,
    message: "",
  });
  const [testState, setTestState] = useState({
    isBlock: true,
    isOk: null,
    isLoading: false,
    message: "",
  });
  const [startState, setStartState] = useState({
    isBlock: true,
    isOk: null,
    isLoading: false,
    message: "",
  });

  const [showUnattachWarning, setShowUnattachWarning] = useState(false);

  useEffect(() => {
    fetchAttachmentThenScheduleQueueCheck();
  }, [competitionId, attacheAgentId]);
  const fetchAttachmentThenScheduleQueueCheck = async () => {
    await fetchAttachment();
    await scheduleFetchUntilEndOfQueue();
  };

  const scheduleFetchUntilEndOfQueue = async () => {
    try {
      const response = await axios.get(
        `/api/direct_attache_agents/competition/${competitionId}/${attacheAgentId}/is_queue`
      );
      if (response.data.isQueue) {
        const createdAtTs = new Date(response.data.createdAtTs);
        const currentUtc = new Date(); // Assuming the server returns UTC time
        const diffInSeconds = Math.floor((currentUtc - createdAtTs) / 1000);
        const positionInQueue = response.data.positionInQueue;
        const message = `Test is initializing...\nRunning since: ${diffInSeconds} sec \nCurrent position in queue: ${positionInQueue}`;

        // Update the test message
        setTestState((prevState) => ({
          ...prevState,
          message,
        }));
        setTimeout(scheduleFetchUntilEndOfQueue, 2000); // Wait 2 sec then retry
      } else {
        fetchAttachment(); // Proceed if not in queue
      }
    } catch (error) {
      console.error("Error checking queue status:", error);
      // setTimeout(scheduleFetchUntilEndOfQueue, 2000); // Retry on error
    }
  };
  const fetchAttachment = async () => {
    setIsFetching(true);
    try {
      const response = await axios.get(
        `/api/direct_attache_agents/competition/${competitionId}/${attacheAgentId}`
      );
      setAttachment(response.data);
      if (response.data.lifecycle) {
        setUploadState({
          ...response.data.lifecycle.upload,
        });
        setTestState({
          ...response.data.lifecycle.test,
        });
        setStartState({
          ...response.data.lifecycle.start,
        });
      }
      if (response.data.agentCode) {
        setagentCode(response.data.agentCode);
      }
      if (response.data.notebook_blueprint_colab) {
        setNotebookBlueprintColab(response.data.notebook_blueprint_colab);
      }
      setDailyAgentRunLimit(response.data?.daily_agent_run_limit ?? 0);
      setTotalDailyRunAllocation(response.data?.total_daily_run_allocation ?? 0);
      setDailyRunAllocation(response.data?.daily_run_allocation ?? 0);
      setNewDailyRunAllocation(response.data?.daily_run_allocation ?? 0);

    } catch (error) {
      console.error("Error fetching attachment:", error);
    }
    setIsFetching(false);
  };

  const updateLifecycleState = async (stateSetter, newState) => {
    stateSetter((prevState) => ({ ...prevState, ...newState }));
  };

  const handleUpload = async (file) => {
    try {
      await updateLifecycleState(setUploadState, { isLoading: true });
      const formData = new FormData();
      formData.append("file", file);
      await axios.put(
        `/api/direct_attache_agents/competition/${competitionId}/${attacheAgentId}/file`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      fetchAttachment();
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      await updateLifecycleState(setUploadState, { isLoading: false });
    }
  };
  const handleTemplate = async () => {
    try {
      await updateLifecycleState(setUploadState, { isLoading: true });
      await axios.put(
        `/api/direct_attache_agents/competition/${competitionId}/${attacheAgentId}/file`,
        { is_template: true }
      );
      fetchAttachment();
    } catch (error) {
      console.error("Error templating file:", error);
    } finally {
      await updateLifecycleState(setUploadState, { isLoading: false });
    }
  };

  const handleFileDelete = async (filename) => {
    try {
      updateLifecycleState(setUploadState, { isLoading: true });
      await axios.put(
        `/api/direct_attache_agents/competition/${competitionId}/${attacheAgentId}/file`,
        { delete_file: filename }
      );
      fetchAttachment();
    } catch (error) {
      console.error("Error deleting file:", error);
    } finally {
      updateLifecycleState(setUploadState, { isLoading: false });
    }
  };

  const handleTest = async () => {
    try {
      updateLifecycleState(setTestState, { isLoading: true });
      await axios.put(
        `/api/direct_attache_agents/competition/${competitionId}/${attacheAgentId}/test`
      );
      fetchAttachmentThenScheduleQueueCheck();
    } catch (error) {
      console.error("Error starting test:", error);
    } finally {
      updateLifecycleState(setTestState, { isLoading: false });
    }
  };

  const handleStart = async () => {
    if (dailyAgentRunLimit - totalDailyRunAllocation + dailyRunAllocation - newDailyRunAllocation < 0){ 
      setShowMaxAgentsModal(true);
    } else {
      try {
        updateLifecycleState(setStartState, { isLoading: true });
        await axios.put(
          `/api/direct_attache_agents/competition/${competitionId}/${attacheAgentId}/start/${newDailyRunAllocation}`
        );
        fetchAttachment();
      } catch (error) {
        console.error("Error joining competition:", error);
      } finally {
        updateLifecycleState(setStartState, { isLoading: false });
      }
    }
  };

  const handleDeleteAttach = async () => {
    try {
      setIsFetching(true);
      await axios.delete(
        `/api/direct_attache_agents/competition/${competitionId}/${attacheAgentId}`
      );
      setAttachment(null);
    } catch (error) {
      console.error("Error deleting attachment:", error);
    } finally {
      setIsFetching(false);
    }
  };
  const handleEditFile = async () => {
    if (!agentCode) return;

    try {
      // Convert the code string into a Blob object
      const blob = new Blob([agentCode], { type: "text/plain" });
      // Create a new File object with the specified filename
      const file = new File([blob], "agent.py");

      // Start the upload process by updating the loading state
      setUploadState((prevState) => ({ ...prevState, isLoading: true }));

      // Prepare the formData with the file
      const formData = new FormData();
      formData.append("file", file);

      // Make the PUT request to upload the file
      await axios.put(
        `/api/direct_attache_agents/competition/${competitionId}/${attacheAgentId}/file`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      // Fetch the updated attachment data
      fetchAttachment();
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      // Update the loading state to false regardless of the result
      setUploadState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  return {
    attachment,
    isFetching,
    uploadState,
    testState,
    startState,
    handleUpload,
    handleTest,
    handleStart,
    handleTemplate,
    handleFileDelete,
    handleDeleteAttach,
    handleEditFile,
    showUnattachWarning,
    setShowUnattachWarning,
    agentCode,
    setagentCode,
    showMaxAgentsModal,
    setShowMaxAgentsModal,
    notebookBlueprintColab,
    dailyAgentRunLimit,
    totalDailyRunAllocation,
    setTotalDailyRunAllocation,
    dailyRunAllocation,
    setDailyRunAllocation,
    newDailyRunAllocation,
    setNewDailyRunAllocation
  };
};
