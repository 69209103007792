import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal, TextInput, Button, Group, Stack, Text, Divider } from '@mantine/core';
import { IconBook, IconRefresh } from '@tabler/icons-react';

const NameYourAgentModal = ({ opened, onClose, competitionId, notebookBlueprintColab }) => {
  const [agentName, setAgentName] = useState('');
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (agentName) {
      const url = `/api/direct_attache_agents/competition/${competitionId}`;
      axios.post(url, { is_direct_attach_name: agentName })
        .then((response) => {
          navigate(`/attach-agent/${competitionId}/${response.data.attache_agent_id}`);
        })
        .catch((error) => {
          console.error("Error saving agent name:", error);
        });
    }
  };

  const generateRandomName = () => {
    const names = [
      "Winston Churchill",
      "Cleopatra",
      "Julius Caesar",
      "Abraham Lincoln",
      "Napoleon Bonaparte",
      "Frodo Baggins",
      "Hermione Granger",
      "Darth Vader",
      "Sherlock Holmes",
      "Harry Potter",
      "Paul Celan",
      "Giannina Braschi",
      "Charles Bukowski",
      "Sylvia Plath",
      "Emily Dickinson",
      "Archimedes",
      "Nikola Tesla",
      "Marie Curie",
      "Leonardo da Vinci",
      "Albert Einstein",
      "Queen Victoria",
      "King Solomon",
      "Joan of Arc",
      "Socrates",
      "Alexander the Great",
      "Elizabeth I",
      "William Shakespeare",
      "Isaac Newton",
      "Charles Darwin",
      "George Washington",
      "Thomas Jefferson",
      "Benjamin Franklin",
      "Gandalf",
      "Luke Skywalker",
      "Indiana Jones",
      "James Bond",
      "Bruce Wayne",
      "Clark Kent",
      "Tony Stark",
      "Peter Parker",
      "Walter White",
      "Don Draper",
      "Michael Scott",
      "Jon Snow",
      "Daenerys Targaryen",
      "Arya Stark",
      "Tyrion Lannister",
      "Obi-Wan Kenobi",
      "Han Solo",
      "Chewbacca",
      "Severus Snape",
      "Albus Dumbledore",
      "Rubeus Hagrid",
      "Link",
      "Mario",
      "Luigi",
      "Bowser",
      "Pikachu",
      "Ash Ketchum",
      "Sonic",
      "Megaman",
      "Pac-Man",
      "Lara Croft",
      "Geralt of Rivia",
      "Yennefer of Vengerberg",
      "Ciri",
      "Triss Merigold",
      "Dandelion",
      "Vesemir",
      "Arwen",
      "Legolas",
      "Gimli",
      "Sauron",
      "Galadriel",
      "Bilbo Baggins",
      "Samwise Gamgee",
      "Gollum",
      "Boromir",
      "Faramir",
      "Denethor",
      "Eowyn",
      "Theoden",
      "Roosevelt",
      "Churchill",
      "Stalin",
      "Mao Zedong",
      "Karl Marx",
      "Freud",
      "Jung",
      "Tesla",
      "Edison",
      "Curie",
      "Hawking",
      "Hubble",
      "Galileo",
      "Kepler",
      "Copernicus",
      "Franklin",
      "Euler",
      "Pythagoras",
      "Euclid",
      "Mandela",
      "Malala Yousafzai",
      "Greta Thunberg",
      "Simone de Beauvoir",
      "Frida Kahlo",
      "Virginia Woolf",
      "Agatha Christie",
      "Jane Austen",
      "J.K. Rowling",
      "Tolkien",
      "Orwell",
      "Hemingway",
      "Fitzgerald",
      "Poe",
      "Dickens",
      "Twain",
      "Chekhov",
      "Dostoevsky",
      "Tolstoy",
      "Pushkin",
      "Goethe",
      "Schiller",
      "Moliere",
      "Voltaire",
      "Rousseau",
      "Balzac",
      "Zola",
      "Flaubert",
      "Hugo",
      "Baudelaire",
      "Mallarme",
      "Rimbaud",
      "Proust",
      "Camus",
      "Sartre",
      "Beauvoir",
      "Homer",
      "Virgil",
      "Dante",
      "Milton",
      "Chaucer",
      "Spenser",
      "Swift",
      "Defoe",
      "Richardson",
      "Fielding",
      "Sterne",
      "Goldsmith",
      "Burns",
      "Blake",
      "Wordsworth",
      "Coleridge",
      "Byron",
      "Shelley",
      "Keats",
      "Tennyson",
      "Browning",
      "Whitman",
      "Emerson",
      "Thoreau",
      "Dickinson",
      "Frost",
      "Pound",
      "Eliot",
      "Yeats",
      "Joyce",
      "Faulkner",
      "Hemingway",
      "Fitzgerald",
      "Steinbeck",
      "Woolf",
      "Lawrence",
      "Orwell",
      "Huxley",
      "Nabokov",
      "Kafka",
      "Salinger",
      "Kerouac",
      "Vonnegut",
      "Bukowski",
      "Plath",
      "Angelou",
      "Hughes",
      "Ginsberg",
      "Pynchon",
      "Murakami",
      "Ondaatje",
      "Atwood",
      "McCarthy",
      "Roth",
      "DeLillo"];
    const randomName = names[Math.floor(Math.random() * names.length)];
    setAgentName(randomName);
  };

  const handleRunJupyterNotebook = () => {
    if (notebookBlueprintColab) {
      window.open(notebookBlueprintColab, "_blank");
    }
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Join Your Agent to the Competition" centered>
      <Stack spacing="md">
        <Group grow>
          <TextInput
            placeholder="Enter agent name"
            value={agentName}
            onChange={(event) => setAgentName(event.currentTarget.value)}
          />
          <Button variant="outline" onClick={generateRandomName} leftIcon={<IconRefresh size={16} />}>
            Random
          </Button>
        </Group>
        <Button onClick={handleSubmit} disabled={!agentName} fullWidth>
          Next step
        </Button>

        {/* {notebookBlueprintColab && (
          <>
            <Divider label="Or" labelPosition="center" />
            <Text size="sm" weight={500}>Start with Notebook Blueprint</Text>
            <Button 
              onClick={handleRunJupyterNotebook} 
              variant="light" 
              leftIcon={<IconBook size={16} />}
              fullWidth
            >
              Run Notebook Blueprint
            </Button>
          </>
        )} */}
      </Stack>
    </Modal>
  );
};

export default NameYourAgentModal;