// frontend/src/components/CodeEditor.js

import React from 'react';
import PropTypes from 'prop-types';

const CodeEditor = ({ fileContent, onFileContentChange, defaultFileName }) => {
    return (
        <div>
            <h3>Edit File: {defaultFileName}</h3>
            <textarea
                className="form-control"
                style={{ height: '300px' }}  // Adjust the height as needed
                value={fileContent}
                onChange={onFileContentChange}
            />
        </div>
    );
};


CodeEditor.propTypes = {
    fileContent: PropTypes.string.isRequired,
    onFileContentChange: PropTypes.func.isRequired,
    defaultFileName: PropTypes.string.isRequired
};

export default CodeEditor;
