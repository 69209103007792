import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, LoadingOverlay, Box } from '@mantine/core';
import { useVideoStream } from "hooks/useVideoStream";
import VideoFileList from "components/Competition/VideoFileList";
import VideoDisplay from "components/Competition/VideoDisplay";

const LiveStream = ({ renderDelay }) => {
  const { competitionId } = useParams();
  const [selectedFileName, setSelectedFileName] = useState([null, null]);
  const { socket_current, isConnected } = useVideoStream(competitionId);

  const selectVideo = (fileName) => {
    setSelectedFileName(fileName);
  };

  return (
    <Box pos="relative" style={{ minHeight: '60vh' }}>
      <LoadingOverlay visible={!isConnected} overlayBlur={2} />
      {isConnected && (
        <Grid gutter="md">
          <Grid.Col span={8}>
            <VideoDisplay
              socket_current={socket_current}
              fileName={selectedFileName[0]}
              fileNameInfo={selectedFileName[1]}
              renderDelay={renderDelay}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <VideoFileList
              socket_current={socket_current}
              competitionId={competitionId}
              onSelectVideo={selectVideo}
              selectedVideo={selectedFileName}
            />
          </Grid.Col>
        </Grid>
      )}
    </Box>
  );
};

export default LiveStream;