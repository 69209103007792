// frontend/src/data/CompetitionData.js

export const getCompetitionData = () => {
    const initialData = {
        name: '',
        description: '',
        start_date_ts: '',
        end_date_ts: '',
        created_at_ts: '',
        is_started: false,
        programmed_end_at_ts: '',
        user_id: '',
        is_public: false
    };

    const setOnceFields = [
        { name: 'name', type: 'text', label: 'Name', placeholder: 'Enter name' }
        // Add other set once fields here
    ];

    const changeableFields = [
        { name: 'description', type: 'textarea', label: 'Description', placeholder: 'Enter description' },
        { name: 'start_date_ts', type: 'datetime-local', label: 'Start Date' },
        { name: 'programmed_end_at_ts', type: 'datetime-local', label: 'Programmed End At' }
        // Add other changeable fields here
    ];

    const fieldsChangedAfterCreation = [
        { name: 'is_started', type: 'checkbox', label: 'Is Started' },
        { name: 'is_public', type: 'checkbox', label: 'Is Public' }
        // Add other fields changed after creation here
    ];

    const displayOnlyFields = [
        { name: 'end_date_ts', label: 'End Date', type: 'date' },
        { name: 'created_at_ts', label: 'Created At', type: 'date' },
        { name: 'user_id', label: 'User ID', type: 'text' }
        // Add other display-only fields here
    ];

    return {
        initialData,
        setOnceFields,
        changeableFields,
        fieldsChangedAfterCreation,
        displayOnlyFields
    };
};

export default getCompetitionData;
