import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ObjectDataComp from "components/ObjectData/ObjectDataComp";
import SaveComponent from "components/ObjectData/SaveComponent";
import DeleteComponent from "components/ObjectData/DeleteComponent";
import useCompetitionData from "hooks/useCompetitionData";
import useConfigurationData from "hooks/useConfigurationData";
import useEnvironmentData from "hooks/useEnvironmentData";
import useAgentData from "hooks/useAgentData";
import useMultipleAgentData from "hooks/useMultipleAgentData";
import getCompetitionData from "data/CompetitionData";
import getCompetitionEnvironmentData from "data/CompetitionEnvironmentData";
import getCompetitionConfigurationData from "data/CompetitionConfigurationData";
import getCompetitionAgentData from "data/CompetitionAgentData";
import {
  saveCompetition,
  saveConfiguration,
  deleteConfiguration,
  deleteCompetition,
  saveEnvironment,
  deleteEnvironment,
  saveAgent,
  deleteAgent,
} from "services/apiService";
import MarkdownEditor from "components/Competition/MarkdownEditor";
import ImageMiniature from "components/Competition/ImageMiniature";

const EditCompetition = () => {
  const { competitionId } = useParams();
  const {
    competitionData,
    competitionLoading,
    competitionError,
    updateCompetitionData,
    configurationId,
    environmentId,
    agentIds,
  } = useCompetitionData(competitionId);
  const {
    configurationData,
    configurationLoading,
    configurationError,
    updateAttachConfigurationData,
  } = useConfigurationData(competitionId, configurationId);

  const {
    environmentData,
    environmentLoading,
    environmentError,
    updateAttachEnvironmentData,
  } = useEnvironmentData(competitionId, environmentId);

  const { agentData, agentLoading, agentError, updateAttachAgentData } =
    useAgentData(competitionId);

  const { agentDatas, agentsLoading, agentsError } =
    useMultipleAgentData(competitionId);

  const isLoading = competitionLoading; // || configurationLoading || environmentLoading || renderLoading || agentsLoading;
  const error = competitionError; // || configurationError || environmentError || renderError || agentsError;
  if (isLoading) return <div>Loading...</div>;
  if (error)
    return <div>Error: {error instanceof Error ? error.message : error}</div>;
  return (
    <div className="container my-4">
      <div className="card mb-4">
        <div className="card-header">
          <h2>Competition Details</h2>
        </div>
        <div className="card-body">
          <ObjectDataComp
            IsPost={!competitionId}
            objectData={competitionData}
            updateObjectData={updateCompetitionData}
            dataParams={getCompetitionData()}
          />
          <SaveComponent
            objectId={competitionId}
            objectData={competitionData}
            apiSave={saveCompetition}
            navpath="/editcompetition/"
          />
          {competitionId && (
            <DeleteComponent
              objectId={competitionId}
              apiDelete={deleteCompetition}
              navpath="/listcompetition/"
            />
          )}
        </div>
      </div>

      {competitionId && (
        <>
          <div className="card mb-4">
            <div className="card-header">
              <h2>Overview</h2>
            </div>
            <div className="card-body">
              <MarkdownEditor
                competitionId={competitionId}
                isViewOnly={false}
              />
            </div>
            <div className="card-body">
              <ImageMiniature
                competitionId={competitionId}
                isViewOnly={false}
              />
            </div>
          </div>
          <div className="card mb-4">
            <div className="card-header">
              <h2>Configuration</h2>
            </div>
            <div className="card-body">
              <ObjectDataComp
                IsPost={!configurationId}
                objectData={configurationData}
                updateObjectData={updateAttachConfigurationData}
                dataParams={getCompetitionConfigurationData(
                  configurationData.engines
                )}
              />
              <SaveComponent
                objectId={configurationId}
                objectData={configurationData}
                apiSave={saveConfiguration}
              />
              {configurationId && (
                <DeleteComponent
                  objectId={configurationId}
                  apiDelete={deleteConfiguration}
                />
              )}
            </div>
          </div>
        </>
      )}

      {configurationId && (
        <div className="card mb-4">
          <div className="card-header">
            <h2>Environment</h2>
          </div>
          <div className="card-body">
            <ObjectDataComp
              IsPost={!environmentId}
              objectData={environmentData}
              updateObjectData={updateAttachEnvironmentData}
              dataParams={getCompetitionEnvironmentData(
                environmentData.environments,
                environmentData.commits
              )}
            />
            <SaveComponent
              objectId={environmentId}
              objectData={environmentData}
              apiSave={saveEnvironment}
            />
            {environmentId && (
              <DeleteComponent
                objectId={environmentId}
                apiDelete={deleteEnvironment}
              />
            )}
          </div>
        </div>
      )}

      {environmentId && (
        <div className="card mb-4">
          <div className="card-header">
            <h2>Agent</h2>
          </div>
          <div className="card-body">
            <ObjectDataComp
              IsPost={true}
              objectData={agentData}
              updateObjectData={updateAttachAgentData}
              dataParams={getCompetitionAgentData(
                agentData.agents,
                agentData.commits
              )}
            />
            <SaveComponent objectData={agentData} apiSave={saveAgent} />
          </div>
        </div>
      )}
      {agentIds.length > 0 && (
        <div className="card mb-4">
          <div className="card-header">
            <h2>Agents List</h2>
          </div>
          <ul className="list-group list-group-flush">
            {agentDatas.map((agentDat, index) => (
              <li className="list-group-item" key={index}>
                <ObjectDataComp
                  IsPost={false}
                  objectData={agentDat}
                  dataParams={getCompetitionAgentData()}
                />
                <DeleteComponent
                  objectId={agentDat.id}
                  apiDelete={deleteAgent}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default EditCompetition;
