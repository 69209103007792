export const initialData = {
  name: "",
};
export const setOnceFields = (options) => [
  { name: "name", type: "text", label: "Name", placeholder: "Enter name" },
];

export const changeableFields = (options) => [];

export const fieldsChangedAfterCreation = (options) => [];

export const displayOnlyFields = (options) => [];
