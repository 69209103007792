import React, { useEffect, useState } from 'react';
import { Card, ScrollArea, Text, Group, Badge, Stack, Divider } from '@mantine/core';
import { formatDistanceToNow } from 'date-fns';
import { UserCircle } from 'lucide-react';

const VideoFileList = ({ socket_current, competitionId, onSelectVideo, selectedVideo }) => {
  const [videoFiles, setVideoFiles] = useState({});

  useEffect(() => {
    if (!socket_current) {
      console.log("Socket not ready");
      return;
    }
    socket_current.emit("request_file_list");
    socket_current.on("file_list_update", (data) => {
      setVideoFiles(data.files);
    });

    return () => {
      socket_current.off("file_list_update");
    };
  }, [socket_current]);

  useEffect(() => {
    if (selectedVideo && selectedVideo[0] === null && Object.keys(videoFiles).length > 0) {
      onSelectVideo([Object.keys(videoFiles)[0], Object.values(videoFiles)[0]]);
    }
  }, [selectedVideo, videoFiles, onSelectVideo]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(
      timestamp.slice(0, 4),
      timestamp.slice(4, 6) - 1,
      timestamp.slice(6, 8),
      timestamp.slice(8, 10),
      timestamp.slice(10, 12)
    );
    return formatDistanceToNow(date, { addSuffix: true });
  };

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder style={{ height: '100%' }}>
      <Text size="xl" weight={700} mb="md">Best Agent Recent Runs</Text>
      <ScrollArea style={{ height: 'calc(80vh - 100px)' }}>
        <Stack spacing="md">
          {Object.entries(videoFiles).map(([fileName, fileData], index) => {
            const timeKey = fileName.split(".")[0];
            const formattedTime = formatTimestamp(timeKey).toUpperCase();
            const players = Object.values(fileData);
            const isActive = selectedVideo && selectedVideo[0] === fileName;

            return (
              <Card 
                key={index}
                shadow="sm" 
                padding="sm" 
                radius="md" 
                withBorder
                style={{
                  backgroundColor: isActive ? '#f0f8ff' : 'white',
                  cursor: 'pointer',
                  transition: 'background-color 0.2s',
                }}
                onClick={() => onSelectVideo([fileName, fileData])}
              >
                <Badge color="gray" size="sm" variant="filled" mb="xs">
                  {formattedTime}
                </Badge>
                <Stack spacing="xs">
                  {players.map((player, idx) => (
                    <Group key={idx} position="apart" noWrap>
                      <Badge 
                        color="blue" 
                        size="lg" 
                        variant="outline" 
                        leftSection={<UserCircle size={14} />}
                        style={{ minWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {player.agent_name}
                      </Badge>
                      <Text size="sm" color="dimmed" style={{ flexGrow: 1, textAlign: 'right' }}>
                        {player.user_name}
                      </Text>
                      <Text 
                        size="sm" 
                        fw={700} 
                        style={{ 
                          minWidth: '60px', 
                          textAlign: 'right',
                          color: player.cum_reward > 0 ? 'green' : player.cum_reward < 0 ? 'red' : 'inherit'
                        }}
                      >
                        {player.cum_reward.toFixed(2)}
                      </Text>
                    </Group>
                  ))}
                </Stack>
              </Card>
            );
          })}
        </Stack>
      </ScrollArea>
    </Card>
  );
};

export default VideoFileList;