// UploadAgent.jsx
import React from 'react';
import { Text, Group, Button, Alert, FileInput, Stack } from '@mantine/core';
import { IconInfoCircle, IconFileImport, IconUpload, IconFlask } from '@tabler/icons-react';

const UploadAgent = ({ state, handleUpload, handleTemplate }) => {
  const getActionButton = (text, icon, handler, disabled, tooltipText) => (
    <Button
      leftIcon={icon}
      onClick={handler}
      disabled={disabled}
      variant="light"
      tooltip={tooltipText}
    >
      {text}
    </Button>
  );

  let content, color, actions;

  if (state.isOk === null) {
    content = 'Please upload your agent.py file, or start with a provided template.';
    color = 'blue';
    actions = [
      getActionButton('Start with Random Agent', <IconFileImport size={14} />, handleTemplate, state.isBlock, 'Begin with a pre-built agent that takes random actions'),
      <FileInput
        key="upload-file-input"
        placeholder="Upload File"
        icon={<IconUpload size={14} />}
        onChange={handleUpload}
        disabled={state.isBlock}
        accept=".py"
      />,
    ];
  } else if (state.isOk === false) {
    content = 'It seems there is some issue with your files, check below to correct them.';
    color = 'red';
    actions = [
      <FileInput
        key="upload-file-input-error"
        placeholder="Upload File"
        icon={<IconUpload size={14} />}
        onChange={handleUpload}
        disabled={state.isBlock}
        accept=".py"
      />,
    ];
  } else if (state.isOk === true) {
    content = 'Your file seems ok, you can add a model or run a dry test.';
    color = 'green';
    actions = [
      <FileInput
        key="upload-file-input-success"
        placeholder="Upload File"
        icon={<IconUpload size={14} />}
        onChange={handleUpload}
        disabled={state.isBlock}
        accept=".py"
      />
    ];
  }

  return (
    <Stack>
      <Text>{content}</Text>
      <Group>{actions}</Group>
      {state.message && (
        <Alert icon={<IconInfoCircle size={16} />} title="Upload Status" color={color}>
          {state.message}
        </Alert>
      )}
    </Stack>
  );
};

export default UploadAgent;