import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Button, Group, Stack, Tabs, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAuth } from 'context/AuthContext';
import NameYourAgentModal from 'components/DirectAgentAttach/NameYourAgentModal';
import CurrentCompetition from 'components/CurrentCompetition';
import Overview from './Overview';
import GettingStarted from './GettingStarted';
import LeaderboardTab from './LeaderboardTab';

const ViewCompetition = () => {
  const { isAdmin, isAuthenticated } = useAuth();
  const { competitionId } = useParams();
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const [competitionInfo, setCompetitionData] = useState({});
  const [environmentInfo, setEnvironmentInfo] = useState({});

  useEffect(() => {
    const fetchCompetition = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_PREFIX_BACKEND}/api/competitions/${competitionId}`,
        {
          credentials: 'include',
        }
      );
      const data = await response.json();
      setCompetitionData(data);
    };

    const fetchEnvironmentInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_PREFIX_BACKEND}/api/attaches/environment/competition/${competitionId}`,
        {
          credentials: 'include',
        }
      );
      const data = await response.json();
      setEnvironmentInfo(data);
    };

    fetchCompetition();
    fetchEnvironmentInfo();
  }, [competitionId]);

  const handleEditCompetition = () => {
    navigate(`/editcompetition/${competitionId}`);
  };

  const handleJoinCompetition = () => {
    if (isAuthenticated) {
      open();
    } else {
      navigate('/login');
    }
  };

  return (
    <>
      <CurrentCompetition competitionId={competitionId} />
      <Container size="lg">
        <Stack spacing="xl">
          <Group position="center">
            <Button onClick={handleJoinCompetition} size="lg" color="blue">
              Join Your Agent To The Competition
            </Button>
            {isAdmin && (
              <Button onClick={handleEditCompetition} size="lg" variant="outline">
                Edit Competition
              </Button>
            )}
          </Group>

          <Tabs defaultValue="overview">
            <Tabs.List>
              <Tabs.Tab value="overview">Overview</Tabs.Tab>
              <Tabs.Tab value="getting-started">Getting Started</Tabs.Tab>
              <Tabs.Tab value="leaderboard">Leaderboard</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="overview">
              <Overview
                competitionId={competitionId}
                startTime={new Date(competitionInfo.start_date_ts)}
                endTime={new Date(competitionInfo.programmed_end_at_ts)}
                renderDelay={competitionInfo.render_delay_second}
                agentMemoryLimit={competitionInfo.agent_memory_limit}
                agentCpuLimit={competitionInfo.agent_cpu_limit}
                agentMaxTimePerStepSecond={competitionInfo.agent_max_time_per_step_second}
              />
            </Tabs.Panel>

            <Tabs.Panel value="getting-started">
              {competitionInfo.id && environmentInfo.id ? (
                <GettingStarted 
                  competitionInfo={competitionInfo}
                  environmentInfo={environmentInfo}
                  handleJoinCompetition={handleJoinCompetition}
                />
              ) : (
                <Text>Loading competition information...</Text>
              )}
            </Tabs.Panel>

            <Tabs.Panel value="leaderboard">
              <LeaderboardTab competitionId={competitionId} />
            </Tabs.Panel>
          </Tabs>

          <NameYourAgentModal
            competitionId={competitionId}
            opened={opened}
            onClose={close}
            notebookBlueprintColab={competitionInfo.notebook_blueprint_colab}
          />
        </Stack>
      </Container>
    </>
  );
};

export default ViewCompetition;