// TextInput.js
import React from 'react';
import PropTypes from 'prop-types';

const TextInput = ({ id, name, value, onChange, placeholder, type }) => {
  let formattedValue = value;
  if (type === 'datetime-local' && value) {
    // Ensure the value is in the correct format for datetime-local input
    const date = new Date(value);
    if (!isNaN(date.getTime())) {
      formattedValue = date.toISOString().slice(0, 16);
    }
  }

  return (
    <input 
      className="form-control"
      type={type || "text"}
      id={id}
      name={name}
      value={formattedValue}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

TextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string
};

export default TextInput;