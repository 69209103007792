// frontend/src/components/ListCommit.js

import React from 'react';
import PropTypes from 'prop-types';

const ListCommit = ({ commits, onCommitSelect, selectedCommit }) => {
    return (
        <div>
            <h3>Commit History</h3>
            <select 
                className="form-select" 
                value={selectedCommit} 
                onChange={(e) => onCommitSelect(e.target.value)}
            >
                {commits.map((commit, index) => {
                    const commitHash = commit.split(' ')[0];
                    return (
                        <option key={index} value={commitHash}>
                            {commit}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};


ListCommit.propTypes = {
    commits: PropTypes.arrayOf(PropTypes.string).isRequired,
    onCommitSelect: PropTypes.func.isRequired,
    selectedCommit: PropTypes.string
};

export default ListCommit;
