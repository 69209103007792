import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextInput, PasswordInput, Button, Stack, Text, Anchor, Title, Container, Paper, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import authService from 'services/authService';

const Register = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const form = useForm({
    initialValues: {
      username: '',
      email: '',
      password: '',
    },
    validate: {
      username: (value) => (value.length < 3 ? 'Username must be at least 3 characters' : null),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (value) => (value.length < 6 ? 'Password must be at least 6 characters' : null),
    },
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await authService.register(values.username, values.email, values.password);
      if (response.success) {
        showNotification({
          title: 'Registration Successful',
          message: response.message || 'Please check your email to activate your account.',
          color: 'green',
          icon: <IconCheck size={16} />,
          autoClose: 10000,
        });
        navigate('/login-mail/sign-in');
      } else {
        if (response.field) {
          // Handle specific field errors (username or email already exists)
          form.setFieldError(response.field, response.message);
        } else {
          // Handle other errors
          showNotification({
            title: 'Registration Failed',
            message: response.message,
            color: 'red',
            icon: <IconX size={16} />,
          });
        }
      }
    } catch (error) {
      showNotification({
        title: 'Registration Failed',
        message: 'An unexpected error occurred. Please try again later.',
        color: 'red',
        icon: <IconX size={16} />,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container size={420} my={40}>
      <Title align="center" sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}>
        Create an account
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Already have an account?{' '}
        <Anchor size="sm" onClick={() => navigate('/login-mail/sign-in')}>
          Login
        </Anchor>
      </Text>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md" pos="relative">
        <LoadingOverlay visible={loading} overlayblur={2} />
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <TextInput
              label="Username"
              placeholder="Your username"
              required
              {...form.getInputProps('username')}
            />
            <TextInput
              label="Email"
              placeholder="Your email"
              required
              {...form.getInputProps('email')}
            />
            <PasswordInput
              label="Password"
              placeholder="Your password"
              required
              {...form.getInputProps('password')}
            />
            <Button fullWidth mt="xl" type="submit">
              Register
            </Button>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
};

export default Register;