// frontend/src/pages/Agent/Edit.js
import React from "react";
import RepositoryManager from "components/Repository/RepositoryManager";
import useFileManagement from "hooks/useFileManagement";

const EditAgent = () => {
  const {
    files,
    uploadFile,
    downloadFile,
    deleteFileOrFolder,
    createFolder,
    storageInfo,
    navigateTo,
    currentPath,
    errorMessage,
    setErrorMessage,
  } = useFileManagement();

  return (
    <div>
      <RepositoryManager basePath="agent" />
    </div>
  );
};

export default EditAgent;
