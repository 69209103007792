import React from 'react';
import { Paper, Title, Select, Code, Tooltip, ActionIcon, Box } from '@mantine/core';
import { IconCopy } from '@tabler/icons-react';
import { useClipboard } from '@mantine/hooks';

const AgentRunImageSelection = ({ selectedImage, setSelectedImage, isGettingStarted = false }) => {
  const clipboard = useClipboard({ timeout: 500 });

  const imageOptions = [
    { value: 'python:3.10-stable-baseline3:2.3.0', label: 'python:3.10 - stable-baseline3:2.3.0' },
  ];

  const requirementsTxt = `python = "3.10"

gymnasium = {extras = ["box2d"], version = "0.29.1"}
stable-baselines3 = "2.3.0"
autorom = {extras = ["accept-rom-license"], version = "0.6.1"}
pettingzoo = {extras = ["classic", atari], version = "1.24.3"}
sb3-contrib = "2.3.0"`;

return (
  <Paper shadow="xs" p="md">
    <Title order={4} mb="md">Agent Runtime Environment</Title>
    <Select
      label="Docker Image for Agent Runtime"
      placeholder="Select a Docker image"
      data={imageOptions}
      value={selectedImage}
      onChange={(value) => setSelectedImage(value)}
      mb="md"
      required
      allowDeselect={false}
    />
    <Title order={5} mb="sm">Requirements</Title>
    <Box pos="relative">
      <Code block p="md" style={{ paddingRight: '40px' }}>
        {requirementsTxt}
      </Code>
      <Tooltip
        label={clipboard.copied ? "Copied!" : "Copy requirements.txt"}
        position="left"
      >
        <ActionIcon
          variant="filled"
          color="blue"
          size="lg"
          style={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            zIndex: 1,
          }}
          onClick={() => clipboard.copy(requirementsTxt)}
        >
          <IconCopy size="1.2rem" />
        </ActionIcon>
      </Tooltip>
    </Box>
  </Paper>
);
};

export default AgentRunImageSelection;