import { useState, useEffect } from "react";
import axios from "axios";

const API_PREFIX = process.env.REACT_APP_PREFIX_BACKEND;

const useFileManagement = (initialFolder = "") => {
  const [files, setFiles] = useState([]);
  const [storageInfo, setStorageInfo] = useState({ used: 0, total: 0 });
  const [currentPath, setCurrentPath] = useState(initialFolder);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    fetchFiles(currentPath);
    fetchStorageInfo();
  }, [currentPath]);

  const fetchStorageInfo = () => {
    axios
      .get(`${API_PREFIX}/api/storage_users/storage_info`)
      .then((response) => {
        const { used, total } = response.data;
        setStorageInfo({ used, total });
      })
      .catch((error) => console.error("Error fetching storage info:", error));
  };

  const fetchFiles = (folder) => {
    const folderPath = folder ? `${folder}` : "";
    axios
      .get(`${API_PREFIX}/api/storage_users/${folderPath}`)
      .then((response) => setFiles(response.data))
      .catch((error) => console.error("Error fetching files:", error));
  };

  // Inside useFileManagement.js

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    // Use the currentPath, not just the initialFolder
    const folderPath = currentPath ? `${currentPath}/` : "";
    const path = `/${folderPath}`.replace(/\/\/+/g, "/");
    axios
      .post(`${API_PREFIX}/api/storage_users${path}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => fetchFiles(currentPath)) // Make sure to refresh the file list for the currentPath
      .catch((error) => {
        console.error("Error uploading file:", error);
        setErrorMessage(
          error.response?.data?.error || "An error occurred during upload"
        );
      });
  };

  const navigateTo = (folder) => {
    const newPath = folder ? `/${folder}`.replace(/\/\/+/g, "/") : "";
    setCurrentPath(newPath);
  };

  const createFolder = (folderName) => {
    if (!folderName.trim()) {
      console.error("Folder name cannot be empty");
      return;
    }

    const folderPath = currentPath ? `${currentPath}/` : "";
    const path = `/${folderPath}`.replace(/\/\/+/g, "/"); // Make sure there are no double slashes

    const payload = {
      folderName: folderName.trim(),
      path: path, // Use the currentPath for folder creation
    };

    axios
      .post(`${API_PREFIX}/api/storage_users/create_folder`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => fetchFiles(currentPath)) // Refresh the file list for the currentPath
      .catch((error) => {
        console.error("Error creating folder:", error);
        setErrorMessage(error.response?.data?.error || "An error occurred"); // Set the error message from response
      });
  };

  // Inside useFileManagement.js

  const deleteFileOrFolder = (itemName, itemType) => {
    const itemPath = currentPath ? `${currentPath}/` : "";
    const path = `/${itemPath}${itemName}`.replace(/\/\/+/g, "/"); // Replace double slashes with a single slash
    axios
      .delete(`${API_PREFIX}/api/storage_users${path}`, {
        data: { type: itemType }, // Send the type (file or folder) as part of the request body
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        fetchFiles(currentPath);
        setErrorMessage(""); // Clear any previous error messages
      })
      .catch((error) => {
        console.error("Error deleting:", error);
        setErrorMessage(error.response?.data?.error || "An error occurred"); // Set the error message from response
      });
  };

  const downloadFile = (fileName) => {
    const fileDownloadPath = currentPath
      ? `${currentPath}/${fileName}`
      : fileName;
    const path = `/${fileDownloadPath}`.replace(/\/\/+/g, "/");
    const url = `${API_PREFIX}/api/storage_users/download${path}`;

    axios
      .get(url, {
        responseType: "blob", // Important to process binary files correctly
      })
      .then((response) => {
        // Create a temporary anchor element and trigger a download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); // Set the file name for download
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        setErrorMessage(
          error.response?.data?.error || "An error occurred during download"
        );
      });
  };

  return {
    files,
    uploadFile,
    downloadFile,
    deleteFileOrFolder,
    createFolder,
    storageInfo,
    navigateTo,
    currentPath,
    errorMessage,
    setErrorMessage,
  };
};

export default useFileManagement;
