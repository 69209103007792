
// TestAgent.jsx
import React from 'react';
import { Text, Group, Alert, Stack, Button } from '@mantine/core';
import { IconInfoCircle, IconFlask } from '@tabler/icons-react';


const TestAgent = ({ state, handleTest }) => {
  let content, color, actions;

  if (state.isLoading) {
    content = 'Your agent is being tested in the environment. Let\'s see if it meets the competition\'s memory and time constraints. It can take some time. Come back when it is ready.';
    color = 'blue';
  } else if (state.isOk === false) {
    content = 'Unfortunately, the test didn\'t pass. Check below and make the needed changes.';
    color = 'red';
  } else if (state.isOk === true) {
    color = 'green';
    if (state.isBlock === false) {
      content = 'Congrats, you are now ready to compete against others.';
    }
  }
  
  return (
    <Stack>
      <Button
      leftIcon={<IconFlask size={14} />}
      onClick={handleTest}
      disabled={(state.isLoading || state.isBlock)}
      variant="light"
      tooltip={'Run Dry test'}
    >
      Test your agent
    </Button>
      <Text>{content}</Text>
      <Group>{actions}</Group>
      {state.message && (
        <Alert icon={<IconInfoCircle size={16} />} title="Test Status" color={color}>
          {state.message.split('\n').map((line, index) => (
            <Text key={index}>{line}</Text>
          ))}
        </Alert>
      )}
    </Stack>
  );
};

export default TestAgent;