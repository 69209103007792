import React from 'react';
import { Table, Text, Badge, Loader, Group, ActionIcon, Tooltip, Box } from '@mantine/core';
import { IconUserCircle, IconTrophy, IconRobot, IconChartBar, IconRun } from '@tabler/icons-react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import useLeaderboardData from "hooks/useLeaderboardData";

const LeaderboardRow = ({ entry, index, competitionId }) => {
  const navigate = useNavigate();

  const handleAgentClick = () => {
    if (entry.agentAttachId) {
      navigate(`/attach-agent/${competitionId}/${entry.agentAttachId}`);
    }
  };

  return (
    <Table.Tr key={index} style={entry.IsMyAgent ? { backgroundColor: '#f0f8ff' } : {}}>
      <Table.Td>
        {entry.Rank <= 2 ? (
          <Tooltip label={`Rank ${entry.Rank}`}>
            <IconTrophy 
              size={24} 
              color={entry.Rank === 1 ? 'gold' : 'silver'} 
            />
          </Tooltip>
        ) : entry.Rank}
      </Table.Td>
      <Table.Td>{entry.Username}</Table.Td>
      <Table.Td>
        <Group gap="xs">
          <Text>{entry.AgentName}</Text>
          {entry.IsMyAgent && (
            <Tooltip label="View Agent Details">
              <ActionIcon 
                color="blue" 
                variant="subtle" 
                onClick={handleAgentClick}
              >
                <IconUserCircle size={16} />
              </ActionIcon>
            </Tooltip>
          )}
        </Group>
      </Table.Td>
      <Table.Td>
        <Group gap="xs">
          <IconChartBar size={16} />
          <Text>{entry.MeanReward != null ? entry.MeanReward.toFixed(2) : 'N/A'}</Text>
        </Group>
      </Table.Td>
      <Table.Td>
        <Group gap="xs">
          <IconRun size={16} />
          <Text>{entry.NumberOfRuns}</Text>
        </Group>
      </Table.Td>
      <Table.Td>{format(new Date(entry.SubscriptionDate), 'yyyy-MM-dd HH:mm')}</Table.Td>
      <Table.Td>
        {entry.LastRun
          ? format(new Date(entry.LastRun), 'yyyy-MM-dd HH:mm')
          : 'N/A'}
      </Table.Td>
    </Table.Tr>
  );
};

const Leaderboard = ({ competitionId }) => {
  const { leaderboardData, loading, error } = useLeaderboardData(competitionId);

  if (loading) return <Loader size="xl" />;
  if (error) return <Text color="red">Error loading leaderboard: {error}</Text>;

  return (
    <Box>
      <Group justify="space-between" mb="md">
        <Group>
          <IconRobot size={24} />
          <Text fw={500} size="lg">Agent Leaderboard</Text>
        </Group>
        <Badge color="blue" variant="light">
          Updated: {format(new Date(), 'yyyy-MM-dd HH:mm')}
        </Badge>
      </Group>

      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Rank</Table.Th>
            <Table.Th>Username</Table.Th>
            <Table.Th>Agent Name</Table.Th>
            <Table.Th>Mean Reward</Table.Th>
            <Table.Th>Number of Runs</Table.Th>
            <Table.Th>Subscription Date</Table.Th>
            <Table.Th>Last Run</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {leaderboardData.map((entry, index) => (
            <LeaderboardRow 
              entry={entry} 
              index={index} 
              key={index} 
              competitionId={competitionId}
            />
          ))}
        </Table.Tbody>
      </Table>
    </Box>
  );
};

export default Leaderboard;