import React from "react";
import PropTypes from "prop-types";
import TextInput from "components/FormElements/TextInput";
import SelectInput from "components/FormElements/SelectInput";
import CheckboxInput from "components/FormElements/CheckboxInput";
import DisplayField from "./DisplayField";
import "styles/FormLayout.css";

const GenericFields = ({
  fields,
  objectData,
  onObjectDataChange,
  isEditable,
}) => {
  return (
    <div>
      {fields.map((field) => (
        <div key={field.name} className="mb-3">
          <label htmlFor={field.name} className="form-label">
            {field.label}:
          </label>
          {isEditable ? (
            field.type === "checkbox" ? (
              <CheckboxInput
                id={field.name}
                name={field.name}
                checked={objectData[field.name]}
                onChange={onObjectDataChange}
                label={field.label}
              />
            ) : field.type === "select" ? (
              <SelectInput
                label={field.label}
                name={field.name}
                value={objectData[field.name]}
                onChange={onObjectDataChange}
                options={field.options}
              />
            ) : (
              <TextInput
                type={field.type}
                id={field.name}
                name={field.name}
                value={objectData[field.name]}
                onChange={onObjectDataChange}
                placeholder={field.placeholder}
              />
            )
          ) : (
            <div className="form-control-plaintext">
              {DisplayField(objectData[field.name], field.type)}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

GenericFields.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  objectData: PropTypes.object.isRequired,
  onObjectDataChange: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

export default GenericFields;