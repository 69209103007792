import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card, Text, Badge, Button, Group, Stack, Modal, LoadingOverlay, Progress,
  Box, Select, TextInput, Grid, Transition, Paper, Title, ActionIcon, Tooltip, Divider,Indicator
} from '@mantine/core';
import { IconTrophy, IconInfoCircle, IconTrash, IconHammer, IconChevronUp, IconChevronDown, IconPlayerPlay, IconPlayerPause, IconFlag, IconFilter, IconSearch, IconCheck, IconX } from '@tabler/icons-react';
import { useMediaQuery } from '@mantine/hooks';
import useMySubscriptions from 'hooks/useMySubscriptions';
import MaxAgentsModal from 'components/MySubscriptions/MaxAgentsModal';
import AllocationControlMySubscriptions from 'components/MySubscriptions/AllocationControlMySubscriptions';
import DailyRunAllocation from 'components/MySubscriptions/DailyRunAllocation'


const ConfirmationModal = ({ opened, onClose, onConfirm, title, body }) => (
  <Modal opened={opened} onClose={onClose} title={title}>
    <Text>{body}</Text>
    <Group position="right" mt="md">
      <Button variant="default" onClick={onClose}>Cancel</Button>
      <Button color="red" onClick={onConfirm}>Confirm</Button>
    </Group>
  </Modal>
);

const MySubscriptions = () => {
  const {
    subscriptions,
    dailyAgentRunLimit,
    totalDailyRunAllocation,
    loading,
    error,
    toggleSubscriptionActiveState,
    deleteSubscription,
  } = useMySubscriptions();
  const navigate = useNavigate();
  const [confirmationModal, setConfirmationModal] = useState({ opened: false, subscription: null, action: '' });
  const [maxAgentsModal, setMaxAgentsModal] = useState(false);
  const [filters, setFilters] = useState({ competition: '', allocation: '', rank: '' });
  const [search, setSearch] = useState('');
  const filteredSubscriptions = useMemo(() => {

    const filtered = subscriptions.filter(sub => {
      const matchesSearch = search === '' || 
        sub.competition_name.toLowerCase().includes(search.toLowerCase()) ||
        sub.agent_name.toLowerCase().includes(search.toLowerCase());
      const matchesCompetition = !filters.competition || sub.competition_name === filters.competition;
      const matchesAllocation = !filters.allocation || 
        (filters.allocation === 'low' && sub.daily_run_allocation < 5) ||
        (filters.allocation === 'medium' && sub.daily_run_allocation >= 5 && sub.daily_run_allocation < 15) ||
        (filters.allocation === 'high' && sub.daily_run_allocation >= 15);
      const matchesRank = !filters.rank || 
        (filters.rank === 'top' && sub.rank !== null && sub.rank <= 10) ||
        (filters.rank === 'middle' && sub.rank !== null && sub.rank > 10 && sub.rank <= 50) ||
        (filters.rank === 'bottom' && (sub.rank === null || sub.rank > 50));
      
      const matches = matchesSearch && matchesCompetition && matchesAllocation && matchesRank;
      return matches;
    });

    return filtered;
  }, [subscriptions, filters, search]);

  const uniqueCompetitions = useMemo(() => 
    [{value: '', label: 'All Competitions'}, 
     ...Array.from(new Set(subscriptions.map(sub => sub.competition_name)))
       .map(name => ({value: name, label: name}))
    ],
    [subscriptions]
  );

  const allocationOptions = [
    { value: '', label: 'All Allocations' },
    { value: 'low', label: 'Low Allocation (< 5)' },
    { value: 'medium', label: 'Medium Allocation (5-15)' },
    { value: 'high', label: 'High Allocation (> 15)' },
  ];

  const rankOptions = [
    { value: '', label: 'All Ranks' },
    { value: 'top', label: 'Top 10' },
    { value: 'middle', label: 'Rank 11-50' },
    { value: 'bottom', label: 'Rank 51+' },
  ];

  const initiateAction = (subscription, action) => {
    setConfirmationModal({ opened: true, subscription, action });
  };

  const handleUpdateAllocation = (subscriptionId, newAllocation) => {
    const subscription = subscriptions.find(sub => sub.id === subscriptionId);
    if (subscription.lifecycle.start.isOk === false &&
        dailyAgentRunLimit - totalDailyRunAllocation + subscription.daily_run_allocation - newAllocation < 0) {
      setMaxAgentsModal(true);
    } else {
      toggleSubscriptionActiveState(subscriptionId, newAllocation);
    }
  };

  const handleConfirmAction = () => {
    if (confirmationModal.action === 'delete') {
      deleteSubscription(confirmationModal.subscription.id);
    }
    setConfirmationModal({ opened: false, subscription: null, action: '' });
  };

  if (error) {
    console.log('Error:', error);
    return <Text color="red">{error}</Text>;
  }

  
  return (
    <Stack spacing="xl">
      <LoadingOverlay visible={loading} />
      
      <DailyRunAllocation dailyAgentRunLimit={dailyAgentRunLimit} totalDailyRunAllocation={totalDailyRunAllocation}/>


      <Paper shadow="xs" p="md" radius="md">
        <Grid align="center" gutter="md">
          <Grid.Col span={6}>
            <TextInput
              icon={<IconSearch size={14} />}
              placeholder="Search agents..."
              value={search}
              onChange={(event) => setSearch(event.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Select
              data={uniqueCompetitions}
              placeholder="Competition"
              value={filters.competition}
              onChange={(value) => setFilters({ ...filters, competition: value })}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Select
              data={allocationOptions}
              placeholder="Allocation"
              value={filters.allocation}
              onChange={(value) => setFilters({ ...filters, allocation: value })}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Select
              data={rankOptions}
              placeholder="Rank"
              value={filters.rank}
              onChange={(value) => setFilters({ ...filters, rank: value })}
              clearable
            />
          </Grid.Col>
        </Grid>
      </Paper>

      <Grid gutter="md">
        {filteredSubscriptions.length > 0 ? (
          filteredSubscriptions.map((subscription) => (
            <Grid.Col key={subscription.id} span={6}>
              <Card shadow="sm" padding="lg" radius="md" withBorder>
                <Group position="apart" mb="xs">
                  <Badge color={subscription.lifecycle.start.isOk === null ? 'yellow' : (subscription.is_started ? 'green' : 'gray')} 
                         leftSection={subscription.lifecycle.start.isOk === null ? <IconPlayerPause size={14} /> : (subscription.is_started ? <IconPlayerPlay size={14} /> : <IconPlayerPause size={14} />)}>
                    {subscription.lifecycle.start.isOk === null ? 'Building' : (subscription.is_started ? 'Active' : 'Paused')}
                  </Badge>
                </Group>

                <Group position="apart" mb="md">
                <Text size="xl" weight={700}>{subscription.agent_name}</Text>
                <Box ml="auto">
                  <Button variant="light" onClick={() => navigate(`/attach-agent/${subscription.competition_id}/${subscription.id}`)}>
                    Manage Agent
                  </Button>
                </Box>
              </Group>


                <Group position="apart" mb="md">
                  <Group spacing="xs">
                    <IconTrophy size={18} />
                    <Text>{subscription.rank || 'N/A'}</Text>
                    <Text>{subscription.competition_name}</Text>
                  </Group>
                  <Box ml="auto">
                  <Button variant="light" onClick={() => navigate(`/viewcompetition/${subscription.competition_id}`)}>
                    View Competition
                  </Button>
                  </Box>
                </Group>

                <AllocationControlMySubscriptions
                  subscription={subscription}
                  dailyAgentRunLimit={dailyAgentRunLimit}
                  totalDailyRunAllocation={totalDailyRunAllocation}
                  onUpdate={handleUpdateAllocation}
                  isStartBlocked={subscription.lifecycle.start.isOk === null}
                />

<Box ml="auto" mt="md">
  <Tooltip label="Delete Agent">
    <ActionIcon color="red" variant="subtle" onClick={() => setConfirmationModal({ opened: true, subscription, action: 'delete' })}>
      <IconTrash size={18} />
    </ActionIcon>
  </Tooltip>
</Box>
              </Card>
            </Grid.Col>
          ))
        ) : (
          <Grid.Col>
            <Text align="center">No subscriptions found matching the current filters.</Text>
          </Grid.Col>
        )}
      </Grid>

      <ConfirmationModal
        opened={confirmationModal.opened}
        onClose={() => setConfirmationModal({ opened: false, subscription: null, action: '' })}
        onConfirm={handleConfirmAction}
        title="Confirm Deletion"
        body="Are you sure you want to delete this agent?"
      />

      <MaxAgentsModal opened={maxAgentsModal} onClose={() => setMaxAgentsModal(false)} />
    </Stack>
  );
};

export default MySubscriptions;