import React from 'react';
import { Stepper, Button, Group, Text, useMantineTheme, FileButton, Tooltip } from '@mantine/core';
import { IconUpload, IconFlask, IconFlag, IconCheck, IconX } from '@tabler/icons-react';

const ProgressSteps = ({
  status: { uploadState, testState, startState },
  handleUpload,
  handleTest,
  handleStart,
}) => {
  const theme = useMantineTheme();

  const getStepState = (state) => {
    if (state.isLoading) return 'loading';
    if (state.isOk) return 'completed';
    if (state.isOk === false) return 'error';
    return 'waiting';
  };

  const getActiveStep = () => {
    if (startState.isOk !== null) return 2;
    if (testState.isOk !== null || testState.isLoading) return 1;
    return 0;
  };

  const renderStepIcon = (icon, state) => {
    if (state === 'completed') return <IconCheck size={28} />;
    if (state === 'error') return <IconX size={28} />;
    return icon;
  };

  return (
    <Stepper active={getActiveStep()} color="blue" size="md">
      <Stepper.Step
        label="Upload Your Agent"
        description="Upload agent.py file"
        icon={renderStepIcon(<IconUpload size={28} />, getStepState(uploadState))}
        state={getStepState(uploadState)}
      >
      </Stepper.Step>

      <Stepper.Step
        label="Choose Runtime & Test"
        description="Test your agent"
        icon={renderStepIcon(<IconFlask size={28} />, getStepState(testState))}
        state={getStepState(testState)}
      >
      </Stepper.Step>

      <Stepper.Step
        label="Allocate Resources & Join"
        description="Start the competition"
        icon={renderStepIcon(<IconFlag size={28} />, getStepState(startState))}
        state={getStepState(startState)}
      >
      </Stepper.Step>
    </Stepper>
  );
};

export default ProgressSteps;