// ForgotPassword.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextInput, Button, Stack, Text, Anchor, Title, Container, Paper, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import authService from 'services/authService';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await authService.forgotPassword(values.email);
      showNotification({
        title: 'Success',
        message: 'Password reset instructions sent to your email.',
        color: 'green',
      });
      navigate('/login-mail/sign-in');
    } catch (error) {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container size={420} my={40}>
      <Title align="center" sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}>
        Forgot password
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Remember your password?{' '}
        <Anchor size="sm" onClick={() => navigate('/login-mail/sign-in')}>
          Login
        </Anchor>
      </Text>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md" pos="relative">
        <LoadingOverlay visible={loading} overlayblur={2} />
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <TextInput
              label="Email"
              placeholder="Your email"
              required
              {...form.getInputProps('email')}
            />
            <Button fullWidth mt="xl" type="submit">
              Reset password
            </Button>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
};

export default ForgotPassword;