import React, { useState, useEffect } from 'react';
import { ThemeIcon, Container, Paper, Title, Group, Stack, Text, Badge, Table, Progress, Alert, Loader, Tabs, Select } from '@mantine/core';
import { IconAlertCircle, IconCheck, IconHourglassHigh, IconX } from '@tabler/icons-react';

const AgentResultsDashboard = ({ competitionId, attacheAgentId }) => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_PREFIX_BACKEND}/api/agent_attached_result/${competitionId}/${attacheAgentId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch results');
        }
        const data = await response.json();
        setResults(data);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchResults();
  }, [competitionId, attacheAgentId]);

  if (isLoading) return <Loader />;
  if (error) return <Alert icon={<IconAlertCircle size="1rem" />} title="Error" color="red">{error}</Alert>;

  const filteredResults = results.filter(run => {
    if (filter === 'all') return true;
    if (filter === 'completed') return run.rabbitmq_status === 'completed' && !run.is_agent_issue;
    if (filter === 'failed') return run.rabbitmq_status === 'completed' && run.is_agent_issue;
    if (filter === 'pending') return ['pending', 'published', 'running'].includes(run.rabbitmq_status);
    return true;
  });

  return (
    <Container size="lg" py="xl">
      <Group position="apart" mb="md">
      <Text size="xl" weight={700}>Agent Results</Text>
        <Select
          value={filter}
          onChange={setFilter}
          data={[
            { value: 'all', label: 'All' },
            { value: 'completed', label: 'Completed' },
            { value: 'failed', label: 'Failed' },
            { value: 'pending', label: 'Pending' }
          ]}
        />
      </Group>
      {filteredResults.map(run => (
        <RunCard key={run.simulation_id} run={run} attacheAgentId={attacheAgentId} />
      ))}
    </Container>
  );
};

const RunCard = ({ run, attacheAgentId }) => {
  const isCompleted = run.rabbitmq_status === 'completed';
  const hasAgentIssue = run.agent_infos.some(agent => agent.is_agent_issue);
  const status = isCompleted 
    ? (hasAgentIssue ? 'Failed' : 'Completed')
    : run.rabbitmq_status.charAt(0).toUpperCase() + run.rabbitmq_status.slice(1);
  const statusColor = isCompleted
    ? (hasAgentIssue ? 'red' : 'green')
    : 'blue';

  return (
    <Paper shadow="xs" p="md" mb="md">
      <Group position="apart">
        <Text weight={700}>Simulation {run.simulation_id}</Text>
        <Badge color={statusColor}>{status}</Badge>
      </Group>
      <Text size="sm">Started: {new Date(run.rabbitmq_created_at_ts).toLocaleString()}</Text>
      {isCompleted && <Text size="sm">Ended: {new Date(run.rabbitmq_updated_at_ts).toLocaleString()}</Text>}
      
      {isCompleted ? (
        
        <CompletedRunDetails agents={run.agent_infos} attacheAgentId={attacheAgentId} />
      ) : (
        <PendingRunDetails agents={run.agent_infos} attacheAgentId={attacheAgentId} />
      )}
    </Paper>
  );
};

const CompletedRunDetails = ({ agents, attacheAgentId }) => {
  return (
    <>
      <Title order={5} mt="md">Agent Performance</Title>
      <Table>
        <thead>
          <tr>
            <th>Agent</th>
            <th>Reward</th>
            <th>Steps</th>
            <th>Avg Action Time</th>
            <th>Max Action Time</th>
            <th>Avg RAM Usage</th>
            <th>Max RAM Usage</th>
            <th>Avg CPU Usage</th>
            <th>Max CPU Usage</th>
            <th>Error/Crash</th>
          </tr>
        </thead>
        <tbody>
          {agents.map(agent => (
            <tr key={agent.agent_id} style={agent.agent_id === parseInt(attacheAgentId) ? { fontWeight: 'bold' } : {}}>
              <td>{agent.is_direct_attach_name}</td>
              <td>{agent.agent_reward?.toFixed(2) || 'N/A'}</td>
              <td>{agent.agent_nb_steps || 'N/A'}</td>
              <td>{agent.action_time_mean_sec?.toFixed(2) || 'N/A'}</td>
              <td>{agent.action_time_max_sec?.toFixed(2) || 'N/A'}</td>
              <td>{agent.agent_metric_perc_ram_mean?.toFixed(2) || 'N/A'}%</td>
              <td>{agent.agent_metric_perc_ram_max?.toFixed(2) || 'N/A'}%</td>
              <td>{agent.agent_metric_cpu_percent_mean?.toFixed(2) || 'N/A'}%</td>
              <td>{agent.agent_metric_cpu_percent_max?.toFixed(2) || 'N/A'}%</td>
              <td>
              {agent.is_agent_issue ? (
                <ThemeIcon color="red" size="sm" radius="xl">
                  <IconX size="0.8rem" />
                </ThemeIcon>
              ) : null}
            </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {agents.map(agent => agent.is_agent_issue && (
        <Alert icon={<IconAlertCircle size="1rem" />} title={`Error in ${agent.is_direct_attach_name}`} color="red" mt="md">
          {agent.agent_code_info_message || agent.agent_pod_info_message || agent.agent_simulation_info_message || 'Unknown error occurred'}
        </Alert>
      ))}
    </>
  );
};

const PendingRunDetails = ({ agents, attacheAgentId }) => {
  return (
    <>
      <Title order={5} mt="md">Agents</Title>
      <Group>
        {agents.map(agent => (
          <Badge key={agent.agent_id} size="lg" variant={agent.agent_id === parseInt(attacheAgentId) ? "filled" : "outline"}>
            {agent.is_direct_attach_name}
          </Badge>
        ))}
      </Group>
    </>
  );
};

export default AgentResultsDashboard;