import { useState, useEffect, useCallback } from 'react';
import { fetchAttachmentCompetition, saveAgent, deleteAgent, fetchAgents, fetchCommits } from 'services/apiService';
import getCompetitionAgentData from 'data/CompetitionAgentData';

export const useAgentData = (competitionId, agentId) => {
  const [agentData, setAgentData] = useState(getCompetitionAgentData().initialData(competitionId));
  const [agentLoading, setLoading] = useState(false);
  const [agentError, setError] = useState(null);

  // Fetch agents initially
  useEffect(() => {
    setLoading(true);
    fetchAgents()
      .then(agents => {
        const formattedAgents = agents.map((agent) => ({
          value: agent.id,
          label: agent.name
        }));

        if(agentId)
        {
        setAgentData(prevState => {
          return { ...prevState, agents: formattedAgents, agent_id: agentData.agent_id };
        });
        }
        else
        {
          setAgentData(prevState => {
            return { ...prevState, agents: formattedAgents, agent_id: formattedAgents[0].value };
          });
        }
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  // Fetch existing agent data for the competition
  useEffect(() => {
    if (agentData.agent_id)
    {
      setLoading(true);
      fetchCommits('agent', agentData.agent_id)
      .then(coms => {
        const formattedCommits = coms.commits.map((commit) => ({
          value: commit.split(' ')[0],
          label: commit.split(' ')[1]
        }));
        if(agentId)
        {
        setAgentData(prevState => {
          return { ...prevState, commits: formattedCommits, publish_commit: agentData.publish_commit };
        });
        }
        else
        {
          setAgentData(prevState => {
            return { ...prevState, commits: formattedCommits, publish_commit: formattedCommits[0].value };
          });
        }
        setLoading(false);
      })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
      }
  }, [agentData.agent_id]);
  

  useEffect(() => {
    if (agentId) {
      setLoading(true);
      fetchAttachmentCompetition(competitionId, 'agent', agentId)
        .then(data => {
          setAgentData(prevData => ({
            ...prevData,
            ...data
          }));
          setLoading(false);
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
    }
  }, [agentId]);

  const updateAttachAgentData = useCallback((updatedData) => {
    setAgentData(prevData => ({
      ...prevData,
      ...updatedData,
    }));
  }, []);

  return {
    agentData,
    agentLoading,
    agentError,
    updateAttachAgentData,
  };
};

export default useAgentData;
