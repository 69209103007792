import { useState, useEffect } from "react";
import { fetchAttachmentCompetition } from "services/apiService";

export const useMultipleAgentData = (competitionId) => {
  const [agentDatas, setAgentDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (competitionId) {
      setLoading(true);
      fetchAttachmentCompetition(competitionId, "agent")
        .then((data) => {
          setAgentDatas(data);
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    }
  }, [competitionId]);

  return {
    agentDatas,
    loading,
    error,
  };
};

export default useMultipleAgentData;
