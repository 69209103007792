// SelectInput.js
import React from 'react';
import PropTypes from 'prop-types';

const SelectInput = ({ label, name, value, onChange, options }) => {
  return (
    <select 
      className="form-control" 
      id={label} 
      name={name} 
      value={value} 
      onChange={onChange}>
      {options.map(option => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
  );
};

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
};

export default SelectInput;
