import React, { useState, useEffect } from 'react';
import { Paper, Stack, Tabs, Card } from '@mantine/core';
import ProgressSteps from 'components/DirectAgentAttach/ProgressSteps';
import UploadAgent from './UploadAgent';
import TestAgent from './TestAgent';
import StartCompetition from './StartCompetition';
import FileManagement from 'components/DirectAgentAttach/File/FileManagement';
import AgentRunImageSelection from './AgentRunImageSelection';

const AgentAttachedJoin = ({
  uploadState,
  testState,
  startState,
  isUploading,
  isTesting,
  isFetching,
  isStarting,
  blockUpload,
  blockTest,
  blockStart,
  handleUpload,
  handleTest,
  handleStart,
  handleTemplate,
  competitionId,
  dailyAgentRunLimit,
  totalDailyRunAllocation,
  dailyRunAllocation,
  newDailyRunAllocation,
  setNewDailyRunAllocation,
  attachment,
  handleFileDelete,
  handleEditFile,
  agentCode,
  setagentCode,
}) => {
  const [selectedImage, setSelectedImage] = useState('python:3.10-stable-baseline3:2.3.0');
  const [activeTab, setActiveTab] = useState('upload');

  useEffect(() => {
    if (testState.isOk) {
      setActiveTab('start');
    } else if (uploadState.isOk) {
      setActiveTab('test');
    } else {
      setActiveTab('upload');
    }
  }, [testState, startState]);

  return (
    <Stack spacing="xl">
      <ProgressSteps
        status={{ uploadState, testState, startState }}
        loading={{ isUploading, isTesting, isStarting }}
        block={{ blockUpload, blockTest, blockStart }}
        handleUpload={handleUpload}
        handleTest={handleTest}
        handleStart={handleStart}
      />

      <Tabs value={activeTab} onChange={setActiveTab}>
        <Tabs.List>
          <Tabs.Tab value="upload">Step 1: Upload Agent</Tabs.Tab>
          {uploadState.isOk && (
            <Tabs.Tab value="test">Step 2: Test Agent</Tabs.Tab>
          )}
          {testState.isOk && (
            <Tabs.Tab value="start">Step 3: Start Competition</Tabs.Tab>
          )}
        </Tabs.List>

        <Tabs.Panel value="upload" pt="md">
          <UploadAgent
            state={uploadState}
            handleUpload={handleUpload}
            handleTemplate={handleTemplate}
          />
          <FileManagement
            files={attachment.files}
            loading={isUploading}
            block={blockUpload}
            handleFileDelete={handleFileDelete}
            handleEditFile={handleEditFile}
            handleUpload={handleUpload}
            agentCode={agentCode}
            setagentCode={setagentCode}
          />
        </Tabs.Panel>

        <Tabs.Panel value="test" pt="md">
          <AgentRunImageSelection
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
          />
          <TestAgent
            state={testState}
            handleTest={handleTest}
          />
        </Tabs.Panel>

        <Tabs.Panel value="start" pt="md">
          <StartCompetition
            state={startState}
            competitionId={competitionId}
            dailyAgentRunLimit={dailyAgentRunLimit}
            totalDailyRunAllocation={totalDailyRunAllocation}
            dailyRunAllocation={dailyRunAllocation}
            newDailyRunAllocation={newDailyRunAllocation}
            setNewDailyRunAllocation={setNewDailyRunAllocation}
            handleStart={handleStart}
          />
        </Tabs.Panel>
      </Tabs>
    </Stack>
  );
};

export default AgentAttachedJoin;