import React, { useState, useEffect } from 'react';
import { Paper, Text, Group, Badge, Box, ScrollArea, Tooltip } from '@mantine/core';
import { useInterval } from '@mantine/hooks';
import { IconClockHour4, IconRobot, IconInfoCircle } from '@tabler/icons-react';
import { formatDistanceToNow, parseISO, isFuture } from 'date-fns';

const SimulationBanner = ({ competitionId }) => {
  const [simulations, setSimulations] = useState([]);

  const fetchSimulations = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_PREFIX_BACKEND}/api/simulation_attached_result/${competitionId}`);
      const data = await response.json();
      setSimulations(data);
    } catch (error) {
      console.error('Error fetching simulation data:', error);
    }
  };

  useEffect(() => {
    fetchSimulations();
  }, [competitionId]);

  useInterval(fetchSimulations, 15000);

  const formatRelativeTime = (dateString) => {
    if (!dateString) return 'N/A';
    const date = parseISO(dateString);
    return isFuture(date) ? `in ${formatDistanceToNow(date)}` : `${formatDistanceToNow(date)} ago`;
  };

  const getStatusColor = (status) => {
    const statusColors = {
      completed: 'green',
      in_progress: 'blue',
      error: 'red',
      published: 'yellow'
    };
    return statusColors[status] || 'gray';
  };

  const formatReward = (reward) => (reward != null ? reward.toFixed(2) : 'N/A');

  return (
    <Paper shadow="xs" p="md" withBorder>
      <ScrollArea>
        <Box style={{ display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap', padding: '10px 0' }}>
          {simulations.map((sim) => (
            <Box key={sim.simulation_id} mr="xl" style={{ display: 'inline-block', minWidth: '200px' }}>
              <Group spacing="xs" align="flex-start" direction="column">
                <Group spacing="xs">
                  <IconClockHour4 size={18} />
                  <Text size="sm">{formatRelativeTime(sim.rabbitmq_created_at_ts)}</Text>
                </Group>
                <Badge color={getStatusColor(sim.rabbitmq_status)} size="lg">
                  {sim.rabbitmq_status}
                </Badge>
                {sim.agent_infos.map((agent, index) => (
                  <Group key={index} spacing="xs" align="center">
                    <IconRobot size={18} />
                    <Tooltip label={agent.is_direct_attach_name || 'Unnamed Agent'} withArrow>
                      <Text size="sm" lineClamp={1} style={{ maxWidth: '100px' }}>
                        {agent.is_direct_attach_name || 'Unnamed Agent'}
                      </Text>
                    </Tooltip>
                    {sim.rabbitmq_status === 'completed' && (
                      <Text size="sm" weight={700} color={parseFloat(agent.agent_reward) >= 0 ? 'green' : 'red'}>
                        {formatReward(agent.agent_reward)}
                      </Text>
                    )}
                  </Group>
                ))}
                {/* <Tooltip label="View simulation details" withArrow>
                  <IconInfoCircle size={18} style={{ cursor: 'pointer' }} />
                </Tooltip> */}
              </Group>
            </Box>
          ))}
        </Box>
      </ScrollArea>
    </Paper>
  );
};

export default SimulationBanner;