import React from 'react';
import { Stack, Tabs, Group, Text, Box } from '@mantine/core';
import AgentResultsDashboard from 'components/DirectAgentAttach/AgentResultsDashboard';
import AllocationControl from 'components/DirectAgentAttach/AllocationControl';
import Configuration from 'components/DirectAgentAttach/Configuration';
import AgentResultOverview from 'components/DirectAgentAttach/AgentResultOverview';
import LeaderboardTab from 'pages/Competition/LeaderboardTab';
import DeleteAttach from 'components/DirectAgentAttach/DeleteAttach';

const AgentAttachedManage = ({
  competitionId,
  attacheAgentId,
  startState,
  dailyAgentRunLimit,
  totalDailyRunAllocation,
  dailyRunAllocation,
  newDailyRunAllocation,
  setNewDailyRunAllocation,
  handleStart,
  name,
  handleDeleteAttach
}) => {
  return (
    <Stack spacing="md">
      <Group position="apart" align="flex-start" noWrap>
        <Group spacing="md" noWrap>
          <Text size="xl" weight={700} style={{ fontSize: '24px' }}>
            {name}
          </Text>
          <DeleteAttach onDelete={handleDeleteAttach} />
        </Group>
        <Box ml="auto">
          <AllocationControl
            key={startState.isOk ? "allocation-control-start" : "allocation-control-paused"}
            isStarted={startState.isOk === true}
            dailyAgentRunLimit={dailyAgentRunLimit}
            totalDailyRunAllocation={totalDailyRunAllocation}
            dailyRunAllocation={dailyRunAllocation}
            newDailyRunAllocation={newDailyRunAllocation}
            setNewDailyRunAllocation={setNewDailyRunAllocation}
            handleStart={handleStart}
            isStartBlocked={startState.isBlock}
          />
        </Box>
      </Group>

      <Tabs defaultValue="dashboard">
        <Tabs.List>
          <Tabs.Tab value="dashboard">Dashboard</Tabs.Tab>
          <Tabs.Tab value="leaderboard">Leaderboard</Tabs.Tab>
          <Tabs.Tab value="configuration">Configuration</Tabs.Tab>
        </Tabs.List>

        <Box mt="md">
          <Tabs.Panel value="dashboard">
            <AgentResultOverview competitionId={competitionId} attacheAgentId={attacheAgentId} />
            <AgentResultsDashboard competitionId={competitionId} attacheAgentId={attacheAgentId} />
          </Tabs.Panel>
          <Tabs.Panel value="leaderboard">
            <LeaderboardTab competitionId={competitionId} />
          </Tabs.Panel>
          <Tabs.Panel value="configuration">
            <Configuration competitionId={competitionId} attacheAgentId={attacheAgentId} />
          </Tabs.Panel>
        </Box>
      </Tabs>
    </Stack>
  );
};

export default AgentAttachedManage;