// frontend/src/data/CompetitionAgentData.js

const getCompetitionAgentData = (agents, commits) => {
  return {
    initialData: (competitionId) => ({
      agent_id: "",
      publish_commit: "",
      competition_id: competitionId,
      agents: [{ value: "", label: "Select Agent" }],
      commits: [{ value: "", label: "Select Commit" }],
    }),
    setOnceFields: [
      {
        name: "agent_id",
        type: "select",
        label: "Agent",
        options: agents || [{ value: "", label: "Select Agent" }],
      },
      {
        name: "publish_commit",
        type: "select",
        label: "Publish Commit",
        options: commits || [{ value: "", label: "Select Commit" }],
      },
    ],
    changeableFields: [],
    fieldsChangedAfterCreation: [],
    displayOnlyFields: [
      { name: "created_at_ts", label: "Created At", type: "date" },
      {
        name: "is_passed_test",
        label: "Passed Test",
        type: "checkbox",
        disabled: true,
      },
    ],
  };
};

export default getCompetitionAgentData;
