import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Button, Stack, Group, TextInput, Textarea } from '@mantine/core';
import CodeEditor from './CodeEditor';
import ListCommit from './ListCommit';
import SaveRepository from './SaveRepository';
import DeleteRepository from './DeleteRepository';
import ObjectDataRepository from 'components/ObjectData/ObjectDataRepository';
import {
  initialData,
  setOnceFields,
  changeableFields,
  fieldsChangedAfterCreation,
  displayOnlyFields,
} from 'data/RepositoryData';
import useFetchAndManageObject from 'hooks/useFetchAndManageObject';
import useFetchAndManageRepositoryContent from 'hooks/useFetchAndManageRepositoryContent';
import useFetchTemplate from 'hooks/useFetchTemplate';

import EnvironmentFields from './EnvironmentFields';

const RepositoryManager = ({ basePath }) => {
  const navigate = useNavigate();
  const { objectId } = useParams();
  const apiEndpoint = `${process.env.REACT_APP_PREFIX_BACKEND}/api/repositories/${basePath}/${objectId || ''}`;
  const [objectData, updateObjectData] = useFetchAndManageObject(apiEndpoint, objectId, initialData);
  const [repositoryContent, updateFileContent, updateSelectedCommit] = useFetchAndManageRepositoryContent(apiEndpoint, objectId);

  const [template, fetchTemplate] = useFetchTemplate(basePath);
  useEffect(() => {
    if (template) {
      updateFileContent(template);
    }
  }, [template]);

  const handleFetchTemplate = () => {
    fetchTemplate();
  };

  const handleSaveSuccess = (data) => {
    if (!objectId && data.id) {
      navigate(`/edit${basePath}/${data.id}`);
    } else {
      window.location.reload();
    }
  };

  const handleDeleteSuccess = () => {
    navigate(`/list${basePath}/`);
  };

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Stack spacing="md">
        <ObjectDataRepository
          IsPost={!objectId}
          objectData={objectData}
          updateObjectData={updateObjectData}
          setOnceFields={setOnceFields()}
          changeableFields={changeableFields()}
          fieldsChangedAfterCreation={fieldsChangedAfterCreation()}
          displayOnlyFields={displayOnlyFields()}
        />
        {basePath === 'environment' && (
          <EnvironmentFields objectId={objectId} />
        )}
        <CodeEditor
          fileContent={repositoryContent.fileContent}
          onFileContentChange={(event) => updateFileContent(event.target.value)}
          defaultFileName={basePath}
        />
        <Group position="apart">
          <Button onClick={handleFetchTemplate} variant="outline">
            Load Template
          </Button>
          {objectId && (
            <ListCommit
              commits={repositoryContent.commits}
              onCommitSelect={(commitHash) => updateSelectedCommit(commitHash)}
              selectedCommit={repositoryContent.selectedCommit}
            />
          )}
        </Group>
        <Group position="apart">
          <SaveRepository
            apiEndpoint={apiEndpoint}
            objectId={objectId}
            fileContent={repositoryContent.fileContent}
            objectData={objectData}
            onSaved={handleSaveSuccess}
          />
          {objectId && (
            <DeleteRepository
              apiEndpoint={apiEndpoint}
              objectId={objectId}
              onDeleted={handleDeleteSuccess}
            />
          )}
        </Group>
      </Stack>
    </Card>
  );
};

export default RepositoryManager;