// FileManagement.jsx
import React, { useState } from 'react';
import { Button, Text, Group, Stack, Modal, List, ThemeIcon, Card, Tooltip, Collapse, Box } from '@mantine/core';
import { IconTrash, IconFileText, IconUpload, IconEdit, IconDeviceFloppy, IconChevronDown, IconChevronUp, IconX } from '@tabler/icons-react';
import { Editor } from '@monaco-editor/react';
import { Dropzone } from '@mantine/dropzone';

const FileManagement = ({
  files,
  loading,
  block,
  handleFileDelete,
  handleEditFile,
  handleUpload,
  agentCode,
  setagentCode,
  handleTemplate,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [showStructure, setShowStructure] = useState(false);

  const editorOptions = {
    minimap: { enabled: false },
    lineNumbers: 'on',
    readOnly: block || loading,
    automaticLayout: true,
  };

  const handleCodeChange = (newValue) => {
    setagentCode(newValue);
  };

  const toggleEditMode = () => {
    if (editMode) {
      handleEditFile(agentCode);
    }
    setEditMode(!editMode);
  };

  const confirmDelete = (file) => {
    setSelectedFile(file);
    setShowConfirmModal(true);
  };

  const deleteFile = () => {
    handleFileDelete(selectedFile);
    setShowConfirmModal(false);
  };

  const handleDrop = (files) => {
    if (files.length > 0) {
      handleUpload(files[0]);
    }
  };

  return (
    <Stack spacing="md">
      <Card shadow="sm" p="lg">
        <Group position="apart" mb="xs">
          <Text weight={500}>Agent Structure</Text>
          <Button
            variant="subtle"
            onClick={() => setShowStructure(!showStructure)}
            rightIcon={showStructure ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
          >
            {showStructure ? 'Hide' : 'Show'}
          </Button>
        </Group>
        <Collapse in={showStructure}>
          <Box mb="md">
            <Text size="sm" mb="xs">Key points:</Text>
            <List size="sm">
              <List.Item>Create a file named `agent.py` with the `Agent` class</List.Item>
              <List.Item>Include the model.zip file (e.g., Stable Baselines3 PPO)</List.Item>
              <List.Item>The `choose_action` method should handle observations of shape: "[1,2,3,4]"</List.Item>
            </List>
          </Box>
          <Editor
            height="200px"
            language="python"
            theme="vs-dark"
            value={`class Agent:
    def __init__(self, env):
        self.env = env
        # from stable_baselines3 import DQN
        # self.model =  DQN.load("model.zip")

    def choose_action(self, observation, action_mask=None):
        # action, _ = self.model.predict(observation, deterministic=True)
        return action`}
            options={{ ...editorOptions, readOnly: true }}
          />
        </Collapse>
      </Card>

      <Card shadow="sm" p="lg">

        <Dropzone
          onDrop={handleDrop}
          onReject={(files) => console.log('rejected files', files)}
          maxSize={3 * 1024 ** 2}
          accept={['application/zip', 'application/x-zip-compressed', 'text/plain', 'text/x-python']}
          disabled={loading || block}
        >
          <Group position="center" spacing="xl" style={{ minHeight: 100, pointerEvents: 'none' }}>
            <Dropzone.Accept>
              <IconUpload size={50} stroke={1.5} />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX size={50} stroke={1.5} />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconFileText size={50} stroke={1.5} />
            </Dropzone.Idle>
            <div>
              <Text size="xl" inline>
                Drag files here or click to select files
              </Text>
              <Text size="sm" color="dimmed" inline mt={7}>
                Attach your agent.py file and any additional files (e.g., model.zip)
              </Text>
            </div>
          </Group>
        </Dropzone>
        <List spacing="xs" size="sm" center icon={
          <ThemeIcon color="teal" size={24} radius="xl">
            <IconFileText size={16} />
          </ThemeIcon>
        }>
          {files.map((file, index) => (
            <List.Item
              key={index}
              icon={
                <ThemeIcon color={file === 'agent.py' ? 'blue' : 'teal'} size={24} radius="xl">
                  <IconFileText size={16} />
                </ThemeIcon>
              }
            >
              <Group position="apart">
                <Text>{file}{file === 'agent.py' && ' (Main File)'}</Text>
                <Group spacing={8}>
                  <Tooltip label="View/Edit File">
                    <Button
                      variant="subtle"
                      color="blue"
                      size="xs"
                      onClick={() => {
                        setEditMode(true);
                        // Fetch and set the content of the file here
                      }}
                      disabled={loading || block}
                    >
                      <IconEdit size={14} />
                    </Button>
                  </Tooltip>
                  <Tooltip label="Delete File">
                    <Button
                      variant="subtle"
                      color="red"
                      size="xs"
                      onClick={() => confirmDelete(file)}
                      disabled={loading || block}
                    >
                      <IconTrash size={14} />
                    </Button>
                  </Tooltip>
                </Group>
              </Group>
            </List.Item>
          ))}
        </List>
      </Card>

      {agentCode && (
        <Card shadow="sm" p="lg">
          <Group position="apart" mb="xs">
            <Text weight={500}>Agent Code</Text>
            <Button
              variant="subtle"
              onClick={toggleEditMode}
              disabled={block || loading}
              leftIcon={editMode ? <IconDeviceFloppy size={14} /> : <IconEdit size={14} />}
            >
              {editMode ? 'Save Changes' : 'Edit Code'}
            </Button>
          </Group>
          <Editor
            height="400px"
            language="python"
            theme="vs-dark"
            value={agentCode}
            options={{ ...editorOptions, readOnly: !editMode }}
            onChange={handleCodeChange}
          />
        </Card>
      )}

      <Modal
        opened={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        title="Confirm File Deletion"
      >
        <Text>Are you sure you want to delete this file?</Text>
        <Group position="right" mt="md">
          <Button variant="light" onClick={() => setShowConfirmModal(false)}>Cancel</Button>
          <Button color="red" onClick={deleteFile}>Delete</Button>
        </Group>
      </Modal>
    </Stack>
  );
};

export default FileManagement;