// ObjectData.js
import React from "react";
import PropTypes from "prop-types";
import GenericFields from "components/FormElements/GenericFields";
import DisplayOnlyFields from "components/FormElements/DisplayOnlyFields";

const ObjectDataComp = ({
  IsPost,
  objectData,
  updateObjectData,
  dataParams,
}) => {
  const {
    setOnceFields,
    changeableFields,
    fieldsChangedAfterCreation,
    displayOnlyFields,
  } = dataParams;
  const handleChange = (e) => {
    const { type, name, checked, value } = e.target;

    // Use checked for checkboxes, value for other types
    const newValue = type === "checkbox" ? checked : value;

    updateObjectData({ [name]: newValue });
  };
  return (
    <div>
      <GenericFields
        fields={
          IsPost
            ? [...setOnceFields, ...changeableFields]
            : [...fieldsChangedAfterCreation, ...changeableFields]
        }
        objectData={objectData}
        onObjectDataChange={handleChange}
        isEditable={true}
      />
      <DisplayOnlyFields
        displayFields={
          IsPost ? displayOnlyFields : [...displayOnlyFields, ...setOnceFields]
        }
        objectData={objectData}
      />
    </div>
  );
};

ObjectDataComp.propTypes = {
  IsPost: PropTypes.bool.isRequired,
  objectData: PropTypes.object.isRequired,
  updateObjectData: PropTypes.func.isRequired,
  // dataParams: PropTypes.object.isRequired,
};

export default ObjectDataComp;
