import { useState, useEffect } from 'react';

const useFetchAndManageObject = (apiEndpoint, objectId, initialData) => {
    const [objectData, setObjectData] = useState(initialData);

    useEffect(() => {
        if (objectId) {
            fetch(`${apiEndpoint}`, { credentials: 'include' })
                .then(response => response.json())
                .then(data => {
                    setObjectData(prev => ({ ...prev, ...data }));
                })
                .catch(error => console.error('Error fetching object data:', error));
        }
    }, [apiEndpoint, objectId]);

    const updateObjectData = (updatedData) => {
        setObjectData(updatedData);
    };

    return [objectData, updateObjectData];
};

export default useFetchAndManageObject;
