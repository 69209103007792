import React from 'react';
import { useParams } from 'react-router-dom';
import { useAgentAttachment } from 'hooks/useDirectAgentAttachment';
import { Container, Paper, Group,Text, Space } from '@mantine/core';
import DeleteAttach from 'components/DirectAgentAttach/DeleteAttach';
import NameAttach from 'components/DirectAgentAttach/NameAttach';
import MaxAgentsModal from 'components/MySubscriptions/MaxAgentsModal';
import CurrentCompetition from 'components/CurrentCompetition';
import AgentAttachedJoin from 'components/DirectAgentAttach/AgentAttachedJoin';
import AgentAttachedManage from 'components/DirectAgentAttach/AgentAttachedManage';


const AgentAttached = () => {
  const { competitionId, attacheAgentId } = useParams();
  const {
    attachment,
    isFetching,
    uploadState,
    testState,
    startState,
    handleUpload,
    handleTest,
    handleStart,
    handleTemplate,
    handleFileDelete,
    handleDeleteAttach,
    handleEditFile,
    showMaxAgentsModal,
    setShowMaxAgentsModal,
    agentCode,
    setagentCode,
    notebookBlueprintColab,
    dailyAgentRunLimit,
    totalDailyRunAllocation,
    dailyRunAllocation,
    newDailyRunAllocation,
    setNewDailyRunAllocation,
  } = useAgentAttachment(competitionId, attacheAgentId);

  const isUploading = uploadState.isLoading;
  const isTesting = testState.isLoading;
  const isStarting = startState.isLoading;

  const blockUpload = uploadState.isBlock;
  const blockTest = testState.isBlock;
  const blockStart = startState.isBlock;


  return (
    <Container size="lg" py="xl">
      <CurrentCompetition competitionId={competitionId} />
      {attachment && (
        <>
          {(startState.isBlock || !testState.isBlock) ? (
                <>
                <Group position="apart" align="center" mb="xl">
                  <Text size="xl" weight={700} style={{ fontSize: '24px' }}>
                    {attachment.name}
                  </Text>
                  <DeleteAttach onDelete={handleDeleteAttach} />
                </Group>
                <Space h="md" />
              
            <AgentAttachedJoin
              uploadState={uploadState}
              testState={testState}
              startState={startState}
              isUploading={isUploading}
              isTesting={isTesting}
              isFetching={isFetching}
              isStarting={isStarting}
              blockUpload={blockUpload}
              blockTest={blockTest}
              blockStart={blockStart}
              handleUpload={handleUpload}
              handleTest={handleTest}
              handleStart={handleStart}
              handleTemplate={handleTemplate}
              competitionId={competitionId}
              notebookBlueprintColab={notebookBlueprintColab}
              dailyAgentRunLimit={dailyAgentRunLimit}
              totalDailyRunAllocation={totalDailyRunAllocation}
              dailyRunAllocation={dailyRunAllocation}
              newDailyRunAllocation={newDailyRunAllocation}
              setNewDailyRunAllocation={setNewDailyRunAllocation}
              attachment={attachment}
              handleFileDelete={handleFileDelete}
              handleEditFile={handleEditFile}
              agentCode={agentCode}
              setagentCode={setagentCode}
            />
            </>
          ) : (
            <AgentAttachedManage
              competitionId={competitionId}
              attacheAgentId={attacheAgentId}
              startState={startState}
              dailyAgentRunLimit={dailyAgentRunLimit}
              totalDailyRunAllocation={totalDailyRunAllocation}
              dailyRunAllocation={dailyRunAllocation}
              newDailyRunAllocation={newDailyRunAllocation}
              setNewDailyRunAllocation={setNewDailyRunAllocation}
              handleStart={handleStart}
              name={attachment.name}
              handleDeleteAttach={handleDeleteAttach}
            />
          )}
        </>
      )}

      <MaxAgentsModal
        show={showMaxAgentsModal}
        handleClose={() => setShowMaxAgentsModal(false)}
      />
    </Container>
  );
};

export default AgentAttached;