
// StartCompetition.jsx
import React from 'react';
import { Text, Group, Button, Alert, Stack } from '@mantine/core';
import { IconInfoCircle, IconTrophy } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import AllocationControl from 'components/DirectAgentAttach/AllocationControl';

const StartCompetition = ({ state, competitionId, dailyAgentRunLimit, totalDailyRunAllocation, dailyRunAllocation, newDailyRunAllocation, setNewDailyRunAllocation, handleStart }) => {
  const navigate = useNavigate();
  let content, color, actions;

  if (state.isOk === true) {
    content = 'Your agent is now on the leaderboard. Let\'s go give a look.';
    color = 'green';
    actions = [
      <Button
        key="go-to-leaderboard"
        leftIcon={<IconTrophy size={14} />}
        onClick={() => navigate(`/viewcompetition/${competitionId}`)}
        variant="light"
      >
        Go to leaderboard
      </Button>,
      <AllocationControl
        key="allocation-control-start"
        isStarted={true}
        dailyAgentRunLimit={dailyAgentRunLimit}
        totalDailyRunAllocation={totalDailyRunAllocation}
        dailyRunAllocation={dailyRunAllocation}
        newDailyRunAllocation={newDailyRunAllocation}
        setNewDailyRunAllocation={setNewDailyRunAllocation}
        handleStart={handleStart}
        isStartBlocked={state.isBlock}
      />,
    ];
  } else if (state.isOk === false) {
    content = 'Your agent is paused. Set a new daily run allocation to restart it.';
    color = 'yellow';
    actions = [
      <AllocationControl
        key="allocation-control-paused"
        isStarted={false}
        dailyAgentRunLimit={dailyAgentRunLimit}
        totalDailyRunAllocation={totalDailyRunAllocation}
        dailyRunAllocation={dailyRunAllocation}
        newDailyRunAllocation={newDailyRunAllocation}
        setNewDailyRunAllocation={setNewDailyRunAllocation}
        handleStart={handleStart}
        isStartBlocked={state.isBlock}
      />,
    ];} else {
      content = 'Congrats, you are now ready to compete against others. Set your daily run allocation and start the competition.';
      color = 'yellow';
      actions = [
        <AllocationControl
        key="allocation-control-test"
        isStarted={false}
        dailyAgentRunLimit={dailyAgentRunLimit}
        totalDailyRunAllocation={totalDailyRunAllocation}
        dailyRunAllocation={dailyRunAllocation}
        newDailyRunAllocation={newDailyRunAllocation}
        setNewDailyRunAllocation={setNewDailyRunAllocation}
        handleStart={handleStart}
        isStartBlocked={false}
        />,
      ]
  }

  return (
    <Stack>
      <Text>{content}</Text>
      <Group>{actions}</Group>
      {state.message && (
        <Alert icon={<IconInfoCircle size={16} />} title="Start Status" color={color}>
          {state.message}
        </Alert>
      )}
    </Stack>
  );
};


export default StartCompetition