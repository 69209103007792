import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';


const SaveComponent = ({ objectId, objectData, onSaved, apiSave, navpath }) => {
    const navigate = useNavigate();
    const handleSave = () => {
        apiSave(objectId, objectData)
        .then((data) => {
            alert(data.message);
            if (onSaved) {
                onSaved(data);
            }
            if (navpath)
            {
            if (objectId)
            {navigate(`${navpath}${objectId}`);}
            else
            {navigate(`${navpath}${data.id}`); }
            }
            else{
                window.location.reload();
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            alert('Error saving data');
            
        });
    };

    return <button onClick={handleSave} className="btn btn-primary">Save</button>;
};

SaveComponent.propTypes = {
    // objectId: PropTypes.number,
    objectData: PropTypes.object.isRequired,
    // onSaved: PropTypes.func,
};

export default SaveComponent;
