import { useState, useEffect, useCallback } from 'react';
import { fetchAttachmentCompetition, saveEnvironment, deleteEnvironment, fetchEnvironments, fetchCommits } from 'services/apiService';
import getCompetitionEnvironmentData from 'data/CompetitionEnvironmentData';

export const useEnvironmentData = (competitionId, environmentId) => {
  const [environmentData, setEnvironmentData] = useState(getCompetitionEnvironmentData().initialData(competitionId));
  const [environmentLoading, setLoading] = useState(false);
  const [environmentError, setError] = useState(null);

  // Fetch environments initially
  useEffect(() => {
    setLoading(true);
    fetchEnvironments()
      .then(envs => {
        const formattedEnvs = envs.map((env) => ({
          value: env.id,
          label: env.name
        }));

        if(environmentId)
        {
        setEnvironmentData(prevState => {
          return { ...prevState, environments: formattedEnvs, environment_id: environmentData.environment_id };
        });
        }
        else
        {
          setEnvironmentData(prevState => {
            return { ...prevState, environments: formattedEnvs, environment_id: formattedEnvs[0].value };
          });
        }
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  // Fetch existing environment data for the competition
  useEffect(() => {
    if (environmentData.environment_id)
    {
      setLoading(true);
      fetchCommits('environment', environmentData.environment_id)
      .then(coms => {
        const formattedCommits = coms.commits.map((commit) => ({
          value: commit.split(' ')[0],
          label: commit.split(' ')[1]
        }));
        if(environmentId)
        {
        setEnvironmentData(prevState => {
          return { ...prevState, commits: formattedCommits, publish_commit: environmentData.publish_commit };
        });
        }
        else
        {
          setEnvironmentData(prevState => {
            return { ...prevState, commits: formattedCommits, publish_commit: formattedCommits[0].value };
          });
        }
        setLoading(false);
      })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
      }
  }, [environmentData.environment_id]);
  

  useEffect(() => {
    if (environmentId) {
      setLoading(true);
      fetchAttachmentCompetition(competitionId, 'environment')
        .then(data => {
          setEnvironmentData(prevData => ({
            ...prevData,
            ...data
          }));
          setLoading(false);
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
    }
  }, [environmentId]);

  const handleSaveEnvironment = useCallback((data) => {
    setLoading(true);
    return saveEnvironment(competitionId, data)
      .then(responseData => {
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [competitionId]);

  const handleDeleteEnvironment = useCallback(() => {
    setLoading(true);
    return deleteEnvironment(competitionId)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [competitionId]);

  const updateAttachEnvironmentData = useCallback((updatedData) => {
    // Update the state with new data
    setEnvironmentData(prevData => ({
      ...prevData,
      ...updatedData,
    }));
  }, []);

  return {
    environmentData,
    environmentLoading,
    environmentError,
    handleSaveEnvironment,
    handleDeleteEnvironment,
    updateAttachEnvironmentData,
  };
};

export default useEnvironmentData;
