// frontend/src/hooks/useFetchTemplate.js

import { useState } from 'react';

const useFetchTemplate = (basePath) => {
  const [template, setTemplate] = useState('');

  const fetchTemplate = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_PREFIX_BACKEND}/api/templates/${basePath}`);
      if (!response.ok) {
        throw new Error('Failed to fetch template');
      }
      const data = await response.json();
      setTemplate(data.file_content);
    } catch (error) {
      console.error('Error fetching template:', error);
    }
  };

  return [template, fetchTemplate];
};

export default useFetchTemplate;
